import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import apiServices from 'services/RequestHandler'
import { dateFormat, timeFormatWithSec, utc_time_zone } from 'store/constants'

export interface JobsState {
    all_jobs: any
    single_job: any
    create_job: any
    candidates: any

    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: JobsState = {
    all_jobs: [],
    single_job: {},
    create_job: {},
    candidates: [],
    activity: false,
}

export const get_all_jobs = createAsyncThunk('/all-jobs', async (data: any) => {
    try {
        let response = await apiServices.getFromApi(
            data.end_point,
            data.permission_name
        )
        return {
            ...response,
        }
    } catch (err) {
        console.log(err)
    }
})

export const get_single_job = createAsyncThunk(
    `/job/:id`,
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(data.end_point, 'job')
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const add_new_job = createAsyncThunk(
    '/job/add',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'job/add'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const get_all_candidates = createAsyncThunk(
    '/all-candidates',
    async (data: any) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                data.permission_name
            )
            return {
                ...response,
            }
        } catch (err) {
            console.log(err)
        }
    }
)

export const upload_job_image = createAsyncThunk(
    'upload/image',
    async (data: any) => {
        try {
            let formData = new FormData()
            formData.append('file', data.body)
            let response = await apiServices.postFromImage(
                data.end_point,
                formData,
                'jobimage',
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )

            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const jobs_slicer = createSlice({
    name: 'careers',
    initialState,
    reducers: {
        handle_clear_form: (state: JobsState, action: any) => {
            state.all_jobs = []
            state.create_job = {}
            state.single_job = {}
            state.candidates = []

            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_all_jobs.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_all_jobs.fulfilled, (state, { payload }) => {
                let jobs = [] as any
                for (let index = 0; index < payload?.data?.length; index++) {
                    const item = payload?.data?.[index]
                    jobs.push([
                        { name: item?.id, hidden: true },
                        item?.post?.en,
                        item?.post?.ge,
                        <p
                            style={{
                                width: '50ch',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {item?.post_description?.en}
                        </p>,
                        {
                            value: item?.is_active,
                            name: item?.is_active ? 'Active' : 'Inactive',
                            is_bool: true,
                            color: item?.is_active ? '#6EC531' : '#d2112b',
                        },
                        {
                            name: item?.created_at
                                ? moment(item?.created_at)
                                      .utcOffset(utc_time_zone)
                                      .format(
                                          `${dateFormat} ${timeFormatWithSec}`
                                      )
                                : '-',
                        },
                        item?.added_by || '-',
                        {
                            name: item?.updated_at
                                ? moment(item?.updated_at)
                                      .utcOffset(utc_time_zone)
                                      .format(
                                          `${dateFormat} ${timeFormatWithSec}`
                                      )
                                : '-',
                        },
                        item?.modified_by || '-',
                    ])
                }
                state.all_jobs = jobs
                state.activity = false
            })

            .addCase(add_new_job.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(add_new_job.fulfilled, (state, { payload }) => {
                try {
                    state.create_job = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(add_new_job.rejected, (state, { payload }) => {
                try {
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(get_single_job.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_single_job.fulfilled, (state, { payload }) => {
                try {
                    state.single_job = payload?.data as any
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })

            .addCase(get_all_candidates.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_all_candidates.fulfilled, (state, { payload }) => {
                try {
                    state.candidates = payload?.data as any
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })
    },
})

export const { handle_clear_form } = jobs_slicer.actions

export default jobs_slicer.reducer
