import MainLayout from "layout/MainLayout"
import {
  Dashboard,
  Category,
  CategoryDetails,
  SubCategory,
  SubCategoryDetails,
  Sizes,
  SizesDetails,
  ProductsPizzaPage,
  PizzaDetails,
  Crust,
  CrustDetails,
  Edge,
  EdgeDetails,
  Toppings,
  ToppingsDetails,
  Bread,
  BreadDetails,
  Chicken,
  ChickenDetails,
  Sandwich,
  SandwichDetails,
  Wrap,
  WrapDetails,
  LoadPotato,
  LoadPotatoDetails,
  Dessert,
  DessertDetails,
  Drinks,
  DrinksDetails,
  Souce,
  SouceDetails,
  Customer,
  CustomerDetails,
  UsersRegister,
  UserDetails,
  RolesRegister,
  RoleDetails,
  StoresRegister,
  StoreDetails,
  ErrorPage,
  NotFound,
  UserProfile,
  BannerRegister,
  BannerDetails,
  Pulse,
  PulseDetails,
  ProductsGroupPage,
  ProductsGroupDetails,
  DealRegsiter,
  DealDetails,
  Draft,
  AwaitingPayment,
  RiderDetails,
  RiderRegister,
  OngoingOrdersRegister,
  OngoingOrdersDetails,
  Candidates,
  JobsRegister,
  OrderHistory,
  SmsManagement,
  JobsDetails,
  RedemePoints,
  PromoCode,
  PromoCodeDetails,
  PointsRegister,
  PointsDetails,
} from "./PageAsync"
import PropTypes from "prop-types"
import { can_perform_action } from "utils/helper"

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: (
          <PrivateRoute
            WrappedComponent={Dashboard}
            classes={classes}
            module_name="Dashboard"
          />
        ),
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute
            WrappedComponent={Dashboard}
            classes={classes}
            module_name="Dashboard"
          />
        ),
      },
      {
        path: "/categories/category",
        element: (
          <PrivateRoute
            WrappedComponent={Category}
            classes={classes}
            module_name="Category"
          />
        ),
      },
      {
        path: "/categories/category/details",
        element: (
          <PrivateRoute
            WrappedComponent={CategoryDetails}
            classes={classes}
            module_name="Category"
          />
        ),
      },
      {
        path: "/categories/category/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={CategoryDetails}
            classes={classes}
            module_name="Category"
          />
        ),
      },
      {
        path: "/categories/sub-category",
        element: (
          <PrivateRoute
            WrappedComponent={SubCategory}
            classes={classes}
            module_name="Sub Category"
          />
        ),
      },
      {
        path: "/categories/sub-category/details",
        element: (
          <PrivateRoute
            WrappedComponent={SubCategoryDetails}
            classes={classes}
            module_name="Sub Category"
          />
        ),
      },
      {
        path: "/categories/sub-category/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={SubCategoryDetails}
            classes={classes}
            module_name="Sub Category"
          />
        ),
      },
      {
        path: "/categories/sizes",
        element: (
          <PrivateRoute
            WrappedComponent={Sizes}
            classes={classes}
            module_name="Sizes"
          />
        ),
      },
      {
        path: "/categories/sizes/details",
        element: (
          <PrivateRoute
            WrappedComponent={SizesDetails}
            classes={classes}
            module_name="Sizes"
          />
        ),
      },
      {
        path: "/categories/sizes/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={SizesDetails}
            classes={classes}
            module_name="Sizes"
          />
        ),
      },
      {
        path: "/products/product-group",
        element: (
          <PrivateRoute
            WrappedComponent={ProductsGroupPage}
            classes={classes}
            module_name="Product Group"
          />
        ),
      },
      {
        path: "/products/product-group/details",
        element: (
          <PrivateRoute
            WrappedComponent={ProductsGroupDetails}
            classes={classes}
            module_name="Product Group"
          />
        ),
      },
      {
        path: "/products/product-group/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ProductsGroupDetails}
            classes={classes}
            module_name="Product Group"
          />
        ),
      },
      {
        path: "/products/pizza/pizza-products",
        element: (
          <PrivateRoute
            WrappedComponent={ProductsPizzaPage}
            classes={classes}
            module_name="Pizza"
          />
        ),
      },
      {
        path: "/products/pizza/pizza-products/details",
        element: (
          <PrivateRoute
            WrappedComponent={PizzaDetails}
            classes={classes}
            module_name="Pizza"
          />
        ),
      },
      {
        path: "/products/pizza/pizza-products/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={PizzaDetails}
            classes={classes}
            module_name="Pizza"
          />
        ),
      },
      {
        path: "/products/pizza/crust",
        element: (
          <PrivateRoute
            WrappedComponent={Crust}
            classes={classes}
            module_name="Crust"
          />
        ),
      },
      {
        path: "/products/pizza/crust/details",
        element: (
          <PrivateRoute
            WrappedComponent={CrustDetails}
            classes={classes}
            module_name="Crust"
          />
        ),
      },
      {
        path: "/products/pizza/crust/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={CrustDetails}
            classes={classes}
            module_name="Crust"
          />
        ),
      },
      {
        path: "/products/pizza/edge",
        element: (
          <PrivateRoute
            WrappedComponent={Edge}
            classes={classes}
            module_name="Edge"
          />
        ),
      },
      {
        path: "/products/pizza/edge/details",
        element: (
          <PrivateRoute
            WrappedComponent={EdgeDetails}
            classes={classes}
            module_name="Edge"
          />
        ),
      },
      {
        path: "/products/pizza/edge/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={EdgeDetails}
            classes={classes}
            module_name="Edge"
          />
        ),
      },
      {
        path: "/products/pizza/toppings",
        element: (
          <PrivateRoute
            WrappedComponent={Toppings}
            classes={classes}
            module_name="Toppings"
          />
        ),
      },
      {
        path: "/products/pizza/toppings/details",
        element: (
          <PrivateRoute
            WrappedComponent={ToppingsDetails}
            classes={classes}
            module_name="Toppings"
          />
        ),
      },
      {
        path: "/products/pizza/toppings/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ToppingsDetails}
            classes={classes}
            module_name="Toppings"
          />
        ),
      },
      {
        path: "/products/bread",
        element: (
          <PrivateRoute
            WrappedComponent={Bread}
            classes={classes}
            module_name="Bread"
          />
        ),
      },
      {
        path: "/products/bread/details",
        element: (
          <PrivateRoute
            WrappedComponent={BreadDetails}
            classes={classes}
            module_name="Bread"
          />
        ),
      },
      {
        path: "/products/bread/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={BreadDetails}
            classes={classes}
            module_name="Bread"
          />
        ),
      },
      {
        path: "/products/chicken",
        element: (
          <PrivateRoute
            WrappedComponent={Chicken}
            classes={classes}
            module_name="Chicken"
          />
        ),
      },
      {
        path: "/products/chicken/details",
        element: (
          <PrivateRoute
            WrappedComponent={ChickenDetails}
            classes={classes}
            module_name="Chicken"
          />
        ),
      },
      {
        path: "/products/chicken/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ChickenDetails}
            classes={classes}
            module_name="Chicken"
          />
        ),
      },
      {
        path: "/products/sandwich",
        element: (
          <PrivateRoute
            WrappedComponent={Sandwich}
            classes={classes}
            module_name="Sandwich"
          />
        ),
      },
      {
        path: "/products/sandwich/details",
        element: (
          <PrivateRoute
            WrappedComponent={SandwichDetails}
            classes={classes}
            module_name="Sandwich"
          />
        ),
      },
      {
        path: "/products/sandwich/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={SandwichDetails}
            classes={classes}
            module_name="Sandwich"
          />
        ),
      },
      {
        path: "/products/wrap",
        element: (
          <PrivateRoute
            WrappedComponent={Wrap}
            classes={classes}
            module_name="Wrap"
          />
        ),
      },
      {
        path: "/products/wrap/details",
        element: (
          <PrivateRoute
            WrappedComponent={WrapDetails}
            classes={classes}
            module_name="Wrap"
          />
        ),
      },
      {
        path: "/products/wrap/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={WrapDetails}
            classes={classes}
            module_name="Wrap"
          />
        ),
      },
      {
        path: "/products/load-potato",
        element: (
          <PrivateRoute
            WrappedComponent={LoadPotato}
            classes={classes}
            module_name="Load Potato"
          />
        ),
      },
      {
        path: "/products/load-potato/details",
        element: (
          <PrivateRoute
            WrappedComponent={LoadPotatoDetails}
            classes={classes}
            module_name="Load Potato"
          />
        ),
      },
      {
        path: "/products/load-potato/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={LoadPotatoDetails}
            classes={classes}
            module_name="Load Potato"
          />
        ),
      },
      {
        path: "/products/dessert",
        element: (
          <PrivateRoute
            WrappedComponent={Dessert}
            classes={classes}
            module_name="Dessert"
          />
        ),
      },
      {
        path: "/products/dessert/details",
        element: (
          <PrivateRoute
            WrappedComponent={DessertDetails}
            classes={classes}
            module_name="Dessert"
          />
        ),
      },
      {
        path: "/products/dessert/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={DessertDetails}
            classes={classes}
            module_name="Dessert"
          />
        ),
      },
      {
        path: "/products/drinks",
        element: (
          <PrivateRoute
            WrappedComponent={Drinks}
            classes={classes}
            module_name="Drinks"
          />
        ),
      },
      {
        path: "/products/drinks/details",
        element: (
          <PrivateRoute
            WrappedComponent={DrinksDetails}
            classes={classes}
            module_name="Drinks"
          />
        ),
      },
      {
        path: "/products/drinks/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={DrinksDetails}
            classes={classes}
            module_name="Drinks"
          />
        ),
      },
      {
        path: "/products/sauce",
        element: (
          <PrivateRoute
            WrappedComponent={Souce}
            classes={classes}
            module_name="Sauce"
          />
        ),
      },
      {
        path: "/products/sauce/details",
        element: (
          <PrivateRoute
            WrappedComponent={SouceDetails}
            classes={classes}
            module_name="Sauce"
          />
        ),
      },
      {
        path: "/products/sauce/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={SouceDetails}
            classes={classes}
            module_name="Sauce"
          />
        ),
      },
      {
        path: "management/customer",
        element: (
          <PrivateRoute
            WrappedComponent={Customer}
            classes={classes}
            module_name="Customer"
          />
        ),
      },
      {
        path: "management/customer/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={CustomerDetails}
            classes={classes}
            module_name="Customer"
          />
        ),
      },
      {
        path: "/settings/users",
        element: (
          <PrivateRoute
            WrappedComponent={UsersRegister}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/users/details",
        element: (
          <PrivateRoute
            WrappedComponent={UserDetails}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/users/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={UserDetails}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/roles",
        element: (
          <PrivateRoute
            WrappedComponent={RolesRegister}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/roles/details",
        element: (
          <PrivateRoute
            WrappedComponent={RoleDetails}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/roles/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={RoleDetails}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/stores",
        element: (
          <PrivateRoute
            WrappedComponent={StoresRegister}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/stores/details",
        element: (
          <PrivateRoute
            WrappedComponent={StoreDetails}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/stores/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={StoreDetails}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/banners",
        element: (
          <PrivateRoute
            WrappedComponent={BannerRegister}
            classes={classes}
            module_name="Banners"
          />
        ),
      },
      {
        path: "/banners/details",
        element: (
          <PrivateRoute
            WrappedComponent={BannerDetails}
            classes={classes}
            module_name="Banners"
          />
        ),
      },
      {
        path: "/banners/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={BannerDetails}
            classes={classes}
            module_name="Banners"
          />
        ),
      },
      {
        path: "/orders/olo",
        element: (
          <PrivateRoute
            WrappedComponent={Pulse}
            classes={classes}
            module_name="OLO"
          />
        ),
      },
      {
        path: "/orders/draft",
        element: (
          <PrivateRoute
            WrappedComponent={Draft}
            classes={classes}
            module_name="Draft"
          />
        ),
      },
      {
        path: "/orders/draft/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={PulseDetails}
            classes={classes}
            module_name="Draft"
          />
        ),
      },
      {
        path: "/orders/awaiting/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={PulseDetails}
            classes={classes}
            module_name="Draft"
          />
        ),
      },
      {
        path: "/orders/awaiting",
        element: (
          <PrivateRoute
            WrappedComponent={AwaitingPayment}
            classes={classes}
            module_name="Awaiting"
          />
        ),
      },
      {
        path: "/orders/olo/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={PulseDetails}
            classes={classes}
            module_name="OLO"
          />
        ),
      },
      {
        path: "/management/coupon",
        element: (
          <PrivateRoute
            WrappedComponent={DealRegsiter}
            classes={classes}
            module_name="Coupon"
          />
        ),
      },
      {
        path: "/management/coupon/details",
        element: (
          <PrivateRoute
            WrappedComponent={DealDetails}
            classes={classes}
            module_name="Coupon"
          />
        ),
      },
      {
        path: "/management/coupon/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={DealDetails}
            classes={classes}
            module_name="Coupon"
          />
        ),
      },
      {
        path: "delivery/courier",
        element: (
          <PrivateRoute
            WrappedComponent={RiderRegister}
            classes={classes}
            module_name="Courier"
          />
        ),
      },
      {
        path: "/delivery/courier/details",
        element: (
          <PrivateRoute
            WrappedComponent={RiderDetails}
            classes={classes}
            module_name="Courier"
          />
        ),
      },
      {
        path: "/delivery/courier/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={RiderDetails}
            classes={classes}
            module_name="Courier"
          />
        ),
      },
      {
        path: "delivery/ongoing-orders",
        element: (
          <PrivateRoute
            WrappedComponent={OngoingOrdersRegister}
            classes={classes}
            module_name="Ongoing Orders"
          />
        ),
      },
      {
        path: "delivery/ongoing-orders/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={OngoingOrdersDetails}
            classes={classes}
            module_name="Ongoing Orders"
          />
        ),
      },
      {
        path: "delivery/order-history",
        element: (
          <PrivateRoute
            WrappedComponent={OrderHistory}
            classes={classes}
            module_name="Order History"
          />
        ),
      },
      {
        path: "careers/job-posting",
        element: (
          <PrivateRoute
            WrappedComponent={JobsRegister}
            classes={classes}
            module_name="Job Posting"
          />
        ),
      },
      {
        path: "careers/job-posting/details/:id?",
        element: (
          <PrivateRoute
            WrappedComponent={JobsDetails}
            classes={classes}
            module_name="Job Posting"
          />
        ),
      },
      {
        path: "points",
        element: (
          <PrivateRoute
            WrappedComponent={PointsRegister}
            classes={classes}
            module_name="Points"
          />
        ),
      },
      {
        path: "points/details/:id?",
        element: (
          <PrivateRoute
            WrappedComponent={PointsDetails}
            classes={classes}
            module_name="Points"
          />
        ),
      },
      {
        path: "careers/candidates",
        element: (
          <PrivateRoute
            WrappedComponent={Candidates}
            classes={classes}
            module_name="Candidates"
          />
        ),
      },
      {
        path: "management/sms-management",
        element: (
          <PrivateRoute
            WrappedComponent={SmsManagement}
            classes={classes}
            module_name="SMS Management"
          />
        ),
      },
      {
        path: "redeme-points",
        element: (
          <PrivateRoute
            WrappedComponent={RedemePoints}
            classes={classes}
            module_name="SMS Management"
          />
        ),
      },
      {
        path: "promo-code",
        element: (
          <PrivateRoute
            WrappedComponent={PromoCode}
            classes={classes}
            module_name="Promo Code"
          />
        ),
      },
      {
        path: "promo-code/details",
        element: (
          <PrivateRoute
            WrappedComponent={PromoCodeDetails}
            classes={classes}
            module_name="Promo Code"
          />
        ),
      },
      {
        path: "promo-code/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={PromoCodeDetails}
            classes={classes}
            module_name="Promo Code"
          />
        ),
      },
      {
        path: "/user-profile",
        element: <UserProfile classes={classes} />,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  }

  return MainRoutes
}

const PrivateRoute = (props: any) => {
  const { WrappedComponent, classes, module_name } = props
  if (can_perform_action(module_name)) {
    return <WrappedComponent classes={classes} module_name={module_name} />
  } else {
    return <ErrorPage classes={classes} />
  }
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
}
