import { makeStyles } from "@mui/styles"
import { defaultLabelColors } from "utils/constants"

const useStyles = makeStyles((theme: any) => ({
  align_center: {
    display: "flex",
    alignItems: "center",
  },

  align_end: {
    display: "flex",
    alignItems: "flex-end",
  },

  justify_center: {
    display: "flex",
    justifyContent: "center",
  },

  justify_end: {
    display: "flex",
    justifyContent: "flex-end",
  },

  direction_row: {
    flexDirection: "row",
  },

  direction_column: {
    flexDirection: "column",
  },

  display_flex_baseline: {
    display: "flex",
    alignItems: "baseline",
  },

  text_red: {
    color: theme.palette.dominos.dominos_red,
  },

  breadcrumb_container: {
    padding: "10px 40px",
    background: theme.palette.dominos.dominos_white,
    boxShadow:
      "0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f",
    marginBottom: 10,
    marginTop: 18,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      marginTop: 10,
      padding: 10,
    },
  },

  green_outlined_button: {
    //#0078ac
    background: theme.palette.dominos.dominos_white,
    color: "#1D6F42",
    borderColor: "#1D6F42",
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#1D6F42",
      color: theme.palette.dominos.dominos_white,
      borderColor: "#1D6F42",
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  blue_outlined_button: {
    background: theme.palette.dominos.dominos_white,
    color: "#0078ac",
    borderColor: "#0078ac",
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#0078ac",
      color: theme.palette.dominos.dominos_white,
      borderColor: "#0078ac",
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  blue_outlined_button_rider: {
    background: theme.palette.dominos.dominos_white,
    color: "#0078ac",
    borderColor: "#ffffff",
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#0078ac",
      color: theme.palette.dominos.dominos_white,
      borderColor: "#ffffff",
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  breadcrumb_head: {
    color: theme.palette.dominos.dominos_red,
    fontFamily: "BebasNeue-Bold",
    fontSize: 30,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textTransform: "uppercase",
  },

  breadcrumb_time: {
    fontFamily: "BebasNeue-Bold",
    color: theme.palette.dominos.white,
    fontSize: 24,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  breadcrumb_description: {
    fontFamily: "Open Sans",
    color: theme.palette.dominos.dominos_red,
    fontSize: 18,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "normal",
    textTransform: "uppercase",
  },

  main_layout_padding: {
    padding: "35px 35px 50px 35px",
  },

  "@media (max-width: 1200px)": {
    main_layout_padding: {
      paddingLeft: "36px",
      paddingTop: "18px",
    },
  },

  "@media (max-width: 600px)": {
    main_layout_padding: {
      paddingLeft: "16px",
      paddingTop: "18px",
    },
  },

  "@media (max-width: 450px)": {
    main_layout_padding: {
      padding: "8px",
    },
  },

  colored_input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.dominos.dominos_blue,
    background: "#fff",
  },

  input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: "#fff",
    color: theme.palette.dominos.black,
  },

  input_feild_2: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "normal",
    background: "#fff",
    color: theme.palette.dominos.black,
  },

  text_feild: {
    resize: "none",
    borderRadius: 4,
    border: "1px solid lightgrey",
    width: "100%",
    height: 120,
    padding: 14,
    "&:focus": {
      outline: `2px solid ${theme.palette.dominos.dominos_red}`,
    },
    "&::placeholder": {
      fontFamily: "Open Sans",
      fontSize: 14,
      fontWeight: "inherit",
      color: "#ababab",
      whiteSpace: "nowrap",
    },
  },

  info_table: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.color_f,
  },

  background_blue: {
    background: theme.palette.dominos.dominos_blue,
  },

  background_light_gray: {
    background: "#e0e0e0",
  },

  table_head: {
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    // letterSpacing: 1,
    color: theme.palette.dominos.dominos_white,
    fontFamily: "Open Sans",
    // textTransform: 'uppercase',
    background: theme.palette.dominos.dominos_red,
    whiteSpace: "nowrap",
    textAlign: "start",
    padding: "18px",
    paddingLeft: `14px !important`,
  },

  table_body: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: 1,
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },

  normal_text: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.dominos.black,
    whiteSpace: "nowrap",
    padding: "24px",
    paddingLeft: "14px",
  },

  small_text: {
    fontSize: 14,
    fontFamily: "Open Sans",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000de",
    fontWeight: 900,
    whiteSpace: "nowrap",
    padding: "20px",
    paddingLeft: "14px",
  },

  simple_normal_text: {
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.dominos.black,
  },

  pagination_per_page: {
    padding: 5,
    borderRadius: 4,
    fontSize: 14,
    color: `${"#d2112b"} !important`,
    background: theme.palette.dominos.dominos_white,
    marginRight: 30,
  },

  color_secondary: {
    // color: `${theme.palette.dominos.dominos_blue} !important`,
    color: `${"#d2112b"} !important`,
  },

  color_primary: {
    color: `${theme.palette.dominos.dominos_dark_blue} !important`,
  },

  bg_gray: {
    backgroundColor: "#f6f6f6",
    background: "#f6f6f6",
  },

  primary_divider: {
    borderColor: theme.palette.dominos.dominos_dark_blue,
    borderBottomWidth: "medium",
  },

  secondary_divider: {
    borderColor: theme.palette.dominos.dominos_blue,
    borderBottomWidth: "medium",
  },

  gray_divider: {
    borderColor: theme.palette.dominos.grey1,
    borderBottomWidth: "medium",
    borderLeftWidth: "medium",
    borderRadius: 10,
  },

  pagination_container: {
    color: "#d2112b",
    "& ul": {
      "& li": {
        "& .Mui-selected": {
          background: "#d2112b",
          // background: theme.palette.dominos.dominos_blue,
          // border: `1px solid ${theme.palette.dominos.dominos_blue}`,
          border: `1px solid #d2112b`,
          color: theme.palette.dominos.dominos_white,
          fontFamily: "Roboto-Regular",
          fontSize: 14,
          borderRadius: 3,
          "&:hover": {
            background: "#d2112b",
            // background: theme.palette.dominos.dominos_blue,
            color: theme.palette.dominos.dominos_white,
          },
        },
        "& button": {
          background: theme.palette.dominos.dominos_white,
          // color: theme.palette.dominos.dominos_blue,
          color: "#d2112b",
        },
      },
    },
  },

  check_icon: {
    color: `${theme.palette.dominos.dominos_blue} !important`,
    height: 24,
    width: 24,
  },

  allowed_selected_row: {
    background: "lightgray",
    boxShadow:
      "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },

  selected_row: {
    border: `2px solid ${theme.palette.dominos.dominos_blue}`,
  },

  unselected_row: {
    borderBottom: `none`,
    borderTop: `2px solid transparent`,
    borderLeft: `2px solid transparent`,
    borderRight: `2px solid transparent`,
  },

  input_label_style: {
    padding: "0px 0px 4px 10px",
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: 900,
    lineHeight: "normal",
    // letterSpacing: '1px',
    color: "#181818",
    // color: theme.palette.dominos.black,
    // textTransform: 'uppercase',
    whiteSpace: "nowrap",
  },

  disable_input_label_style: {
    padding: "0px 0px 4px 10px",
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: 900,
    lineHeight: "normal",
    // letterSpacing: '1px',
    color: "#989898",
    // color: theme.palette.dominos.black,
    // textTransform: 'uppercase',
    whiteSpace: "nowrap",
  },

  typography_heading_style: {
    padding: "0px 0px 4px 10px",
    fontFamily: "BebasNeue-Bold",
    fontSize: 22,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px",
    color: theme.palette.dominos.black,
    textTransform: "uppercase",
  },

  white_input_label_style: {
    padding: "0px 0px 4px 10px",
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: 900,
    lineHeight: "normal",
    // letterSpacing: '1px',
    color: theme.palette.dominos.dominos_white,
    // textTransform: 'uppercase',
  },

  color_red: {
    color: `red !important`,
  },

  button_width: {
    minWidth: 120,
    maxHeight: 43,
    minHeight: 43,
    textTransform: "none",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      minWidth: "0px",
      marginBottom: 5,
    },
  },

  green_contained_button: {
    background: "green",
    borderColor: "green",
    color: theme.palette.dominos.dominos_white,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    "&:hover": {
      background: "green",
      borderColor: "green",
      color: theme.palette.dominos.dominos_white,
      transition: "0.2s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  contained_button: {
    background: theme.palette.dominos.dominos_red,
    borderColor: theme.palette.dominos.dominos_red,
    color: theme.palette.dominos.dominos_white,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    "&:hover": {
      background: theme.palette.dominos.dominos_red,
      borderColor: theme.palette.dominos.dominos_red,
      color: theme.palette.dominos.dominos_white,
      transition: "0.2s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  blue_contained_button: {
    background: theme.palette.dominos.dominos_blue,
    color: theme.palette.dominos.dominos_white,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    textTransform: "uppercase",
    "&:hover": {
      background: theme.palette.dominos.dominos_blue,
      color: theme.palette.dominos.dominos_white,
      transition: "0.2s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  future_contained_button: {
    background: theme.palette.dominos.dominos_blue,
    color: theme.palette.dominos.dominos_white,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    textTransform: "uppercase",
    "&:hover": {
      background: theme.palette.dominos.dominos_blue,
      color: theme.palette.dominos.dominos_white,
      transition: "0.2s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  numpad_button: {
    color: "#ffffff",
    fontSize: 25,
    background: theme.palette.dominos.dominos_red,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    "&:hover": {
      color: "#ffffff",
      fontSize: 25,
      background: theme.palette.dominos.dominos_red,
    },
  },

  red_numpad_button: {
    color: "#ffffff",
    fontSize: 25,
    background: theme.palette.dominos.dominos_red,
    borderColor: theme.palette.dominos.dominos_red,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    "&:hover": {
      color: "#ffffff",
      fontSize: 25,
      background: theme.palette.dominos.dominos_red,
      borderColor: theme.palette.dominos.dominos_red,
    },
  },

  green_numpad_button: {
    color: "#ffffff",
    fontSize: 25,
    background: "green",
    borderColor: "green",
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    "&:hover": {
      color: "#ffffff",
      fontSize: 25,
      background: "green",
      borderColor: "green",
    },
  },

  check_in_button: {
    color: "#ffffff",
    fontSize: 25,
    background: theme.palette.dominos.dominos_blue,
    borderColor: theme.palette.dominos.dominos_blue,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    "&:hover": {
      color: "#ffffff",
      fontSize: 25,
      background: theme.palette.dominos.dominos_blue,
      borderColor: theme.palette.dominos.dominos_blue,
    },
  },

  outlined_button: {
    background: theme.palette.dominos.dominos_white,
    color: theme.palette.dominos.dominos_red,
    borderColor: theme.palette.dominos.dominos_red,
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    "&:hover": {
      background: theme.palette.dominos.dominos_red,
      color: theme.palette.dominos.dominos_white,
      borderColor: theme.palette.dominos.dominos_red,
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  red_outlined_button: {
    background: theme.palette.dominos.dominos_white,
    color: theme.palette.dominos.dominos_red,
    borderColor: theme.palette.dominos.dominos_red,
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: theme.palette.dominos.dominos_red,
      color: theme.palette.dominos.dominos_white,
      borderColor: theme.palette.dominos.dominos_red,
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  disabled_button: {
    background: theme.palette.dominos.grey1,
    color: theme.palette.dominos.grey3,
    borderColor: theme.palette.dominos.grey1,
    textTransform: "uppercase",
  },

  modal_background: {
    backgroundColor: theme.palette.dominos.dominos_red,
    background: theme.palette.dominos.dominos_red,
    padding: "10px 20px",
  },

  modal_title: {
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    color: defaultLabelColors.color_h,
    letterSpacing: "0.015em",
    fontFamily: "BebasNeue-Bold",
  },

  card_label_style: {
    fontSize: 16,
    color: "gray",
    fontFamily: "Roboto-Medium",
  },

  card_value_style: {
    fontSize: 16,
    color: theme.palette.dominos.black,
    fontFamily: "Roboto-Medium",
  },

  profile_name: {
    fontSize: 20,
    color: theme.palette.dominos.black,
    fontFamily: "Roboto-Bold",
    letterSpacing: 1.5,
  },

  accordion_head_container: {
    backgroundColor: `${theme.palette.dominos.dominos_red} !important`,
    background: `${theme.palette.dominos.dominos_red} !important`,
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: "3px 3px 0px 0px ",
    minHeight: `38px !important`,
    height: `38px !important`,
  },

  accordion_heading: {
    fontSize: 20,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.015em",
    color: theme.palette.dominos.black,
    fontFamily: "Roboto-Medium",
  },

  accordion_icons: {
    fontSize: 20,
    color: theme.palette.dominos.grey3,
  },

  accordion_container: {
    border: `1px solid ${theme.palette.dominos.dominos_red}`,
  },

  accordion_detail_padding: {
    padding: "24px 32px 30px",
  },

  toggle_sections: {
    color: theme.palette.dominos.dominos_blue,
    fontSize: 16,
    paddingLeft: 15,
    fontFamily: "Roboto-Regular",
    cursor: "pointer",
  },

  view_image_text: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.dominos.dominos_blue,
  },

  dialog_footer_info: {
    fontSize: 14,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.dominos.grey3,
    width: "max-content",
  },

  dialog_description: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.dominos.grey3,
    fontFamily: "Roboto-Medium",
  },

  dialog_description_2: {
    fontSize: 16,
    color: theme.palette.dominos.dominos_blue,
    fontFamily: "RobotoCondensed-Regular",
  },

  radio_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.dominos.dominos_blue,
  },

  unselect_radio_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.dominos.grey3,
  },

  form_container: {
    background: "#ffffff",
    padding: 20,
    border: `2px solid ${theme.palette.dominos.dominos_white}`,
    [theme.breakpoints.down("sm")]: {},
  },

  form_container_without_padding: {
    background: "#ffffff",
    [theme.breakpoints.down("sm")]: {},
  },

  setting_icons: {
    color: theme.palette.dominos.dominos_blue,
    height: 60,
    width: "100%",
  },

  setting_container: {
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {},
  },

  setting_heading: {
    fontSize: 18,
    fontFamily: "Roboto-Medium",
  },

  setting_description: {
    fontSize: 15,
    fontFamily: "Roboto-Regular",
    color: theme.palette.dominos.grey3,
  },

  setting_hover: {
    transition: "0.5s",
    padding: 10,
    borderRadius: 10,
    "&:hover": {
      background: theme.palette.dominos.dominos_red,
      transition: "0.5s",
    },
  },

  switch_label: {
    fontSize: 16,
    fontFamily: "Roboto-Regular",
    color: theme.palette.dominos.dominos_red,
  },

  rate_dialog_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.dominos.grey3,
  },

  responsive_width: {
    width: "30%",
    [theme.breakpoints.down("xl")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  commission_label: {
    fontSize: 16,
    color: theme.palette.dominos.grey3,
    fontFamily: "RobotoCondensed-Medium",
  },

  dashboard_section_heading: {
    fontSize: 30,
    fontFamily: "BebasNeue-Bold",
    color: theme.palette.dominos.dominos_red,
    fontWeight: 400,
    marginBottom: "12px !important",
    marginRight: "18px !important",
    textTransform: "uppercase",
  },

  dashboard_card_head: {
    fontSize: 34,
    color: theme.palette.dominos.dominos_red,
    fontFamily: "BebasNeue-Bold",
  },

  dashboard_card_titles: {
    color: "#98b2cc",
  },

  dashboard_card_title: {
    fontSize: 18,
    color: theme.palette.dominos.dominos_red,
    fontFamily: "Open Sans",
    whiteSpace: "nowrap",
    fontWeight: 600, //specific
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dashboard_card_container: {
    padding: "10px 18px",
    border: `1px solid ${theme.palette.dominos.dominos_red}`,
    borderRadius: 5,
    position: "relative",
    boxShadow: `rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px`,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    minHeight: "7rem",
    maxHeight: "128px",
    margin: "0px 28px 36px 0px",
    background: "white",
  },

  auto_renew_icon: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 8,
    fontSize: 16,
    cursor: "pointer",
    color: theme.palette.dominos.dominos_red,
  },

  active_tab_button: {
    background: theme.palette.dominos.dominos_white,
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    letterSpacing: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    color: `${theme.palette.dominos.dominos_red} !important`,
    border: `2px solid ${theme.palette.dominos.dominos_red}`,
  },

  inactive_tab_button: {
    background: theme.palette.dominos.dominos_red,
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    color: "#ffffff !important",
    letterSpacing: 1,
  },

  typography_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
  },

  typography_sales_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 28,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
  },

  typography_white_heading: {
    fontFamily: "BebasNeue-Bold",
    fontSize: 22,
    textTransform: "uppercase",
    color: theme.palette.dominos.dominos_white,
  },

  typography_login_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 28,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
    color: theme.palette.dominos.dominos_white,
    whiteSpace: "nowrap",
  },

  typography_employee_time_clock_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
    color: theme.palette.dominos.dominos_white,
  },

  typography_caption: {
    fontFamily: "Roboto-Condensed-Regular",
    fontWeight: 600,
    fontSize: 13,
    lineHeight: 1.334,
    letterSpacing: 1,
    marginLeft: 1,
    marginTop: 1,
    color: theme.palette.dominos.dominos_white,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.dominos.dominos_red,
    },
  },

  typography_sales_subtitle: {
    fontFamily: "Roboto-Condensed-Regular",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.334,
    letterSpacing: 1,
    marginLeft: 1,
    marginTop: 1,
    color: theme.palette.dominos.black,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.dominos.dominos_red,
    },
  },

  app_bar: {
    backgroundColor: theme.palette.dominos.dominos_red,
    boxShadow: "none",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  expansion_panel_summary: {
    backgroundColor: theme.palette.dominos.dominos_red,
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },

  expand_more_icon: {
    color: "white",
  },

  link_error: {
    fontWeight: "bold",
    color: "#000000",
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "BebasNeue-Book",
    textTransform: "uppercase",
  },

  red_app_bar: {
    backgroundColor: theme.palette.dominos.dominos_red,
    boxShadow: "none",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  accordin_summary_inactive: {
    marginTop: "14px !important",
    letterSpacing: "1px !important",
    fontFamily: "BebasNeue-Bold",
    fontSize: 24,
    fontWeight: 900,
    lineHeight: "normal",
    textTransform: "uppercase",
    paddingBlock: "8px",
    borderRadius: "4px !important",
    color: theme.palette.dominos.dominos_red,
    background: theme.palette.dominos.dominos_white,
    outline: `2px solid ${theme.palette.dominos.dominos_red}`,
  },

  accordin_summary_active: {
    marginTop: "14px !important",
    letterSpacing: "1px !important",
    fontFamily: "BebasNeue-Bold",
    fontSize: 24,
    fontWeight: 900,
    lineHeight: "normal",
    textTransform: "uppercase",
    paddingBlock: "8px",
    borderRadius: "4px !important",
    color: theme.palette.dominos.dominos_white,
    background: theme.palette.dominos.dominos_red,
    outline: `2px solid ${theme.palette.dominos.dominos_red}`,
  },

  expanded_icon: {
    color: theme.palette.dominos.dominos_black,
  },

  expanded_icon_active: {
    color: theme.palette.dominos.dominos_white,
  },

  sorting_arrows: {
    cursor: "pointer",
    color: theme.palette.dominos.dominos_red,
    border: `1px solid ${theme.palette.dominos.dominos_red}`,
    width: "40px",
    margin: "0px 4px",
    borderRadius: "2px",
  },

  sorting_arrows_disabled: {
    color: "lightgrey",
    border: "1px solid lightgrey",
    width: "40px",
    margin: "0px 4px",
    borderRadius: "2px",
  },

  radio_button: {
    color: theme.palette.dominos.dominos_black,
    "&.Mui-checked": {
      color: theme.palette.dominos.dominos_black,
    },
  },

  red_active_tab_button: {
    background: theme.palette.dominos.dominos_white,
    color: theme.palette.dominos.dominos_red,
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    letterSpacing: 1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  red_inactive_tab_button: {
    background: theme.palette.dominos.dominos_red,
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    color: "#ffffff !important",
    letterSpacing: 1,
  },

  border_table: {
    border: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    textAlign: "center",
  },

  custom_border_table: {
    borderRight: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    borderLeft: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    borderBottom: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    // border: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    textAlign: "center",
  },

  table_headings_custom: {
    fontFamily: "Open Sans",
    fontSize: 15,
    fontWeight: 900,
    lineHeight: "normal",
    // letterSpacing: '1px',
    color: theme.palette.dominos.dominos_red,
    // textTransform: 'uppercase',
    background: "white",
    textAlign: "center",
    paddingBlock: "15px",
    borderRight: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    borderTop: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    borderBottom: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    // border: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    width: "250px",
  },

  table_headings_custom1: {
    fontFamily: "Open Sans",
    fontSize: 15,
    fontWeight: 900,
    lineHeight: "normal",
    // letterSpacing: '1px',
    color: theme.palette.dominos.dominos_red,
    // textTransform: 'uppercase',
    background: "white",
    textAlign: "center",
    paddingBlock: "15px",
    borderTop: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    borderBottom: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    // border: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    width: "250px",
  },

  white_table_cell: {
    backgroundColor: "white !important",
    color: "black !important",
  },

  table_headings: {
    fontFamily: "Open Sans",
    fontSize: 15,
    fontWeight: 900,
    lineHeight: "normal",
    // letterSpacing: '1px',
    color: theme.palette.dominos.dominos_red,
    // textTransform: 'uppercase',
    background: "white",
    textAlign: "center",
    paddingBlock: "15px",
    border: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    width: "250px",
  },

  table_headings_width: {
    fontFamily: "BebasNeue-Book",
    fontSize: 18,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px",
    color: theme.palette.dominos.dominos_red,
    // textTransform: 'uppercase',
    background: "white",
    textAlign: "center",
    paddingBlock: "15px",
    border: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
  },

  table_card_heading: {
    color: "#ffffff",
    fontSize: "24px",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px !important",
    textTransform: "uppercase",
  },
  table_card_sub_heading: {
    color: "#ffffff",
    fontSize: "18px",
    paddingBottom: "10px",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px !important",
    textTransform: "uppercase",
  },

  table_label_style: {
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: 900,
    lineHeight: "normal",
    // letterSpacing: '1px',
    // color: theme.palette.dominos.black,
    textTransform: "capitalize",
    width: "250px",
    color: "#000000de",
  },

  table_label_style_width: {
    fontFamily: "BebasNeue-Book",
    fontSize: 18,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px",
    color: theme.palette.dominos.black,
    textTransform: "uppercase",
  },

  orders_table: {
    fontFamily: "BebasNeue-Book",
    fontSize: 16,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px",
    color: theme.palette.dominos.dominos_white,
    textTransform: "uppercase",
    paddingBlock: "15px",
    border: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    width: "200px",
  },

  orders_table_olo: {
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "normal",
    // letterSpacing: '1px',
    color: theme.palette.dominos.dominos_white,
    // textTransform: 'uppercase',
    paddingBlock: "15px",
    border: `1.6px solid ${theme.palette.dominos.dominos_red} !important`,
    width: "200px",
  },

  checkbox_div: {
    border: "1px solid lightgray",
    borderRadius: "5px",
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: "#fff",
    color: "black",
  },

  disabled_checkbox: {
    color: `${theme.palette.dominos.dominos_red} !important`,
  },

  selectedRow: {
    background: "antiquewhite",
  },

  sticky: {
    position: "sticky",
    left: 0,
  },

  rider_register_main: {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  courier_map_main: {
    backgroundColor: "#ffffff",
    border: "1px solid #d2112b",
    marginBottom: 20,
    borderRadius: 5,
    paddingLeft: "40px",
  },
  courier_height_tem: {
    height: "100vh",
    overflowY: "scroll",
    padding: "0px 10px 10px 0px",
  },
  courier_box_border: {
    height: "35vh",
    background: "#ffffff",
    border: "1px solid lightgray",
    // border:
    //     selectedRecord?.courier_id ===
    //     x?.courier_id
    //         ? '2px solid #d2112b'
    //         : '1px solid lightgray',
    borderRadius: "5px",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    marginBottom: 15,
  },
  courier_box_ptag: {
    height: "80px",
    background: "#d2112b",
    // background:
    //     '#0B648F',
    fontFamily: "Open Sans",
    fontSize: "15px",
    color: "#181818",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: 900,
    border: "1px solid lightgray",
    padding: "0 10px",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  courier_icon_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f7f7f7",
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  courier_name_css: {
    color: "#ffffff",
    fontFamily: "Open Sans",
    paddingLeft: "15px",
    flex: 1,
  },
  courier_last_location: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#ffffff",
  },
  last_location_css: {
    fontWeight: 500,
    fontSize: "11px",
    color: "#ffffff",
  },
  courier_img_shadow_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#f7f7f7",
    borderRadius: "50%",
    width: "34px",
    height: "34px",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
  courier_information_main: {
    padding: "15px",
    fontSize: "14px",
    lineHeight: "15px",
  },
  courier_information_space: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  courier_id_font_main: {
    fontFamily: "Open Sans",
  },
  courier_id_font: {
    fontFamily: "Open Sans",
    fontWeight: 900,
  },
  divider_main: {
    paddingTop: 10,
  },
  courier_footer_main: {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
  },
  courier_footer_space: {
    width: "fit-content",
    textAlign: "center",
    fontFamily: "Open Sans",
  },
  courier_footer_font: {
    fontFamily: "Open Sans",
    color: "#181818",
  },
  courier_main_map: {
    height: "65vh",
    background: "#ffffff",
    border: "1px solid lightgray",
    borderRadius: "5px",
  },
}))

export default useStyles
