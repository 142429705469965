import { LogoutOutlined, Menu, Person } from '@mui/icons-material'
import { Avatar, Box, ButtonBase, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import useStyles from 'routes/style-jss'
import apiServices from 'services/RequestHandler'
import useCookiesFunction from 'utils/cookies.utils'
import {
    clearDataFromLocalStorage,
    getDataFromLocalStorage,
} from 'utils/localStore.utils'
import logo from '../../../assets/images/logo/dominos-icon.png'
import LogoSection from '../LogoSection'

const Header = ({ handleLeftDrawerToggle, drawerOpen }: any) => {
    const classes = useStyles() as any
    const theme = useTheme() as any
    const { removeCookie } = useCookiesFunction()
    const navigate = useNavigate()
    const user = getDataFromLocalStorage('user')

    return (
        <>
            {/* LOGO & TOGGLER BUTTON */}
            <Box
                sx={{
                    width: drawerOpen ? 245 : 48,
                    display: 'flex',
                    paddingBlock: '8px',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                    justifyContent: drawerOpen ? 'space-around' : 'flex-start',
                    background: '#ffffff',
                    height: 65,
                }}
            >
                {drawerOpen && <LogoSection />}
                <ButtonBase
                    sx={{ borderRadius: '12px', overflow: 'hidden' }}
                    aria-label={drawerOpen ? 'Toggle menu' : 'Toggle drawer'}
                >
                    {drawerOpen ? (
                        <Avatar
                            variant="rounded"
                            sx={{
                                transition: 'all .2s ease-in-out',
                                background: 'transparent',
                                color: 'white',
                                '&:hover': {
                                    color: theme.palette.dominos.dominos_white,
                                },
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <Menu
                                style={{
                                    fontSize: 38,
                                    marginTop: '15px',
                                    color: '#d2112b',
                                }}
                            />
                        </Avatar>
                    ) : (
                        <img
                            src={logo}
                            width={50}
                            height={40}
                            alt={'Dominos Logo'}
                            onClick={handleLeftDrawerToggle}
                        />
                    )}
                </ButtonBase>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <Grid item className={'breadcrumb_time'}>
                <div
                    style={{ color: '#ffffff', textTransform: 'uppercase' }}
                    className={classNames(classes.breadcrumb_description)}
                >
                    {user?.firstname + ' ' + user?.lastname} &nbsp; &nbsp;
                </div>
            </Grid>

            <Person
                style={{
                    fontSize: 28,
                    color: 'white',
                    marginRight: 10,
                    cursor: 'pointer',
                    width: 30,
                    height: 30,
                }}
                onClick={() => {
                    navigate('/user-profile')
                }}
            />

            <LogoutOutlined
                style={{
                    fontSize: 28,
                    color: 'white',
                    marginRight: 20,
                    cursor: 'pointer',
                    width: 30,
                    height: 30,
                }}
                onClick={async () => {
                    await apiServices.postFromApi(
                        '/users/admin-logout',
                        {},
                        'users'
                    )
                    clearDataFromLocalStorage('token')
                    clearDataFromLocalStorage('user')
                    removeCookie('is_logged_in')
                    removeCookie('token')

                    const cookies = document.cookie.split(';')
                    for (let i = 0; i < cookies.length; i++) {
                        const cookie = cookies[i]
                        const eqPos = cookie.indexOf('=')
                        const name =
                            eqPos > -1 ? cookie.substr(0, eqPos) : cookie
                        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`
                    }

                    navigate('/login')
                }}
            />
        </>
    )
}

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    drawerOpen: PropTypes.bool,
}

export default Header
