import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import apiServices from 'services/RequestHandler'
import { dateFormat, timeFormatWithSec, utc_time_zone } from './constants'

export interface ItemsState {
    all_deals: any
    all_deals_data: any
    single_deal: any
    create_deal: any
    update_deal: any
    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: ItemsState = {
    all_deals: [],
    all_deals_data: [],
    single_deal: {},
    create_deal: {},
    update_deal: {},

    activity: false,
}

export const all_deal = createAsyncThunk('/deal', async (data: any) => {
    try {
        let response = await apiServices.getFromApi(
            data.end_point,
            data.permission_name
        )
        return {
            ...response,
        }
    } catch (err) {
        console.log(err)
    }
})

export const get_single_deal = createAsyncThunk(
    `/deal/:id`,
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(data.end_point, 'deal')
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const create_deal_function = createAsyncThunk(
    '/deal/add',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'deal/add'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const update_deal_function = createAsyncThunk(
    'deal/update',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'deal'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const deal_slicer = createSlice({
    name: 'deal',
    initialState,
    reducers: {
        handle_clear_form: (state: ItemsState, action: any) => {
            state.single_deal = {}
            state.create_deal = {}
            state.update_deal = {}
            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(all_deal.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(all_deal.fulfilled, (state, { payload }) => {
                try {
                    let users = [] as any

                    for (
                        let index = 0;
                        index < payload?.data?.length;
                        index++
                    ) {
                        const item = payload?.data[index]
                        users.push([
                            { name: item?._id || '--', hidden: true },
                            item?.deal_code || '--',
                            {
                                name: moment(item?.end_date)
                                    .utcOffset(utc_time_zone)
                                    .format(
                                        `${dateFormat} ${timeFormatWithSec}`
                                    ),
                            },
                            {
                                name: item?.combined === true ? 'Yes' : 'No',
                            },
                            {
                                name: item?.is_active === true ? 'Yes' : 'No',
                            },
                            {
                                name: item?.is_active === true ? 'Yes' : 'No',
                            },
                        ])
                    }

                    state.all_deals_data = payload?.data
                    state.all_deals = users
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })

            .addCase(create_deal_function.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(create_deal_function.fulfilled, (state, { payload }) => {
                try {
                    state.create_deal = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(create_deal_function.rejected, (state, { payload }) => {
                try {
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(
                update_deal_function.pending,
                (state: any, { payload }: any) => {
                    state.activity = true
                }
            )

            .addCase(
                update_deal_function.fulfilled,
                (state: any, { payload }: any) => {
                    try {
                        state.update_deal = payload
                        state.activity = false
                    } catch (error) {
                        state.activity = false
                    }
                }
            )

            .addCase(get_single_deal.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_single_deal.fulfilled, (state, { payload }) => {
                try {
                    state.single_deal = {
                        ...payload?.data,
                        time: new Date(),
                    } as any
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })
    },
})

export const { handle_clear_form } = deal_slicer.actions

export default deal_slicer.reducer
