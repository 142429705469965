import {
  language_translation,
  reusable_translation,
  search_filters_translation,
  status_translation,
  payment_methods_translation,
  product_status_translation,
  stores_details_translation,
  delivery_methods_translation,
  deal_type,
  discount_type,
  updated_channel_name_translation,
} from "translation"
import { set_localize_content } from "./helper"

export const export_label = "EXPORT"
export const print_label = "PRINT"
export const download_label = "DOWNLOAD"
export const GOOGLE_MAPS_APIKEY = "AIzaSyDck8jd3P-F9dSPmSwzhKkIbCtvL4vzExs"

export const defaultLabelColors = {
  color_e: "#000000",
  color_f: "#666666",
  color_g: "#eeeeee",
  color_h: "#f8f8f8",
}

export const stores_list = [
  { label: "Kostava", value: 25990, code: 25990 },
  { label: "Vake", value: 25991, code: 25991 },
  { label: "Vazha", value: 25992, code: 25992 },
  { label: "Gldani", value: 25993, code: 25993 },
  { label: "Isani", value: 25994, code: 25994 },
  { label: "Varketili", value: 25995, code: 25995 },
  { label: "Delisi", value: 25996, code: 25996 },
]

export const olo_statueses = [
  { label: "Pending", value: "pending" },
  { label: "Preparing", value: "makeline" },
  { label: "Baking", value: "oven" },
  { label: "Quality check", value: "routing station" },
  { label: "Almost Ready", value: "almost ready" },
  { label: "On It's Way", value: "out the door" },
  { label: "Delivered/Completed", value: "complete" },
  { label: "Bad", value: "bad" },
  { label: "Cancelled", value: "void" },
  { label: "Future", value: "future" },
  { label: "Abandoned", value: "abandoned" },
  { label: "Rejected", value: "rejected" },
]

export const awaiting_statuses = [
  { label: "Failed Payment", value: "failed_payment" },
  { label: "Awaiting Payment", value: "awaiting_payment" },
]

export const dateRangeFilters = [
  {
    label: set_localize_content(
      search_filters_translation?.all_time_records,
      "en"
    ),
    value: "all_time",
  },
  {
    label: set_localize_content(search_filters_translation?.this_year, "en"),
    value: "this_year",
  },
  {
    label: set_localize_content(search_filters_translation?.this_quarter, "en"),
    value: "this_quarter",
  },
  {
    label: set_localize_content(search_filters_translation?.this_month, "en"),
    value: "this_month",
  },
  {
    label: set_localize_content(search_filters_translation?.this_week, "en"),
    value: "this_week",
  },
  {
    label: set_localize_content(search_filters_translation?.today, "en"),
    value: "today",
  },
  {
    label: set_localize_content(search_filters_translation?.yesterday, "en"),
    value: "yesterday",
  },
  {
    label: set_localize_content(
      search_filters_translation?.previous_week,
      "en"
    ),
    value: "previous_week",
  },
  {
    label: set_localize_content(
      search_filters_translation?.previous_month,
      "en"
    ),
    value: "previous_month",
  },
]

export const date_range_filter = [
  {
    label: set_localize_content(search_filters_translation?.this_year, "en"),
    value: "this_year",
  },
  {
    label: set_localize_content(search_filters_translation?.this_quarter, "en"),
    value: "this_quarter",
  },
  {
    label: set_localize_content(search_filters_translation?.this_month, "en"),
    value: "this_month",
  },
  {
    label: set_localize_content(search_filters_translation?.this_week, "en"),
    value: "this_week",
  },
  {
    label: set_localize_content(search_filters_translation?.today, "en"),
    value: "today",
  },
  {
    label: set_localize_content(search_filters_translation?.yesterday, "en"),
    value: "yesterday",
  },
  {
    label: set_localize_content(
      search_filters_translation?.previous_week,
      "en"
    ),
    value: "previous_week",
  },
  {
    label: set_localize_content(
      search_filters_translation?.previous_month,
      "en"
    ),
    value: "previous_month",
  },
  {
    label: set_localize_content(search_filters_translation?.custom_date, "en"),
    value: "custom date",
  },
]

export const date_range_filter_2 = [
  {
    label: set_localize_content(search_filters_translation?.this_year, "en"),
    value: "this_year",
  },
  {
    label: set_localize_content(search_filters_translation?.this_quarter, "en"),
    value: "this_quarter",
  },
  {
    label: set_localize_content(search_filters_translation?.this_month, "en"),
    value: "this_month",
  },
  {
    label: set_localize_content(search_filters_translation?.this_week, "en"),
    value: "this_week",
  },
  {
    label: set_localize_content(search_filters_translation?.last_7_days, "en"),
    value: "last_7_days",
  },
  {
    label: set_localize_content(search_filters_translation?.yesterday, "en"),
    value: "yesterday",
  },
  {
    label: set_localize_content(
      search_filters_translation?.previous_week,
      "en"
    ),
    value: "previous_week",
  },
  {
    label: set_localize_content(
      search_filters_translation?.previous_month,
      "en"
    ),
    value: "previous_month",
  },
  {
    label: set_localize_content(search_filters_translation?.custom_date, "en"),
    value: "custom date",
  },
]

export const payment_methods = [
  {
    label: set_localize_content(
      payment_methods_translation.card_on_delivery,
      "en"
    ),
    value: set_localize_content(
      payment_methods_translation.card_on_delivery,
      "en"
    ),
  },
  {
    label: set_localize_content(
      payment_methods_translation.cash_on_delivery,
      "en"
    ),
    value: set_localize_content(
      payment_methods_translation.cash_on_delivery,
      "en"
    ),
  },
  {
    label: set_localize_content(
      payment_methods_translation.credit_or_debit_card,
      "en"
    ),
    value: set_localize_content(
      payment_methods_translation.credit_or_debit_card,
      "en"
    ),
  },
]

export const online_payment_methods = [
  {
    label: set_localize_content(payment_methods_translation.card, "en"),
    value: 5,
  },
  {
    label: set_localize_content(payment_methods_translation.apple_pay, "en"),
    value: 9,
  },
  {
    label: set_localize_content(payment_methods_translation.google_pay, "en"),
    value: 14,
  },
]

export const delivery_methods = [
  {
    label: set_localize_content(delivery_methods_translation.delivery, "en"),
    value: "Delivery",
  },
  {
    label: set_localize_content(delivery_methods_translation.takeaway, "en"),
    value: "Takeaway",
  },
  {
    label: set_localize_content(delivery_methods_translation.dinein, "en"),
    value: "Dinein",
  },
]

export const channels_type = [
  {
    label: set_localize_content(updated_channel_name_translation.web, "en"),
    value: "web",
  },
  {
    label: set_localize_content(updated_channel_name_translation.android, "en"),
    value: "android",
  },
  {
    label: set_localize_content(updated_channel_name_translation.ios, "en"),
    value: "ios",
  },
  {
    label: set_localize_content(
      updated_channel_name_translation.callcenter,
      "en"
    ),
    value: "call_center",
  },
  {
    label: set_localize_content(updated_channel_name_translation.pulse, "en"),
    value: "pulse",
  },
]

export const product_channels = [
  {
    label: set_localize_content(updated_channel_name_translation.web, "en"),
    value: "web",
    available: true,
    channel_type: "all",
  },
  {
    label: set_localize_content(updated_channel_name_translation.android, "en"),
    value: "android",
    available: true,
    channel_type: "all",
  },
  {
    label: set_localize_content(updated_channel_name_translation.ios, "en"),
    value: "ios",
    available: true,
    channel_type: "all",
  },
  {
    label: set_localize_content(
      updated_channel_name_translation.callcenter,
      "en"
    ),
    value: "call_center",
    available: true,
    channel_type: "all",
  },
  {
    label: set_localize_content(updated_channel_name_translation.wolt, "en"),
    value: "wolt",
    available: true,
    channel_type: "product",
  },
  {
    label: set_localize_content(updated_channel_name_translation.bolt, "en"),
    value: "bolt",
    available: false,
    channel_type: "product",
  },
  {
    label: set_localize_content(updated_channel_name_translation.glovo, "en"),
    value: "glovo",
    available: false,
    channel_type: "product",
  },
  {
    label: set_localize_content(updated_channel_name_translation.yandex, "en"),
    value: "yandex",
    available: false,
    channel_type: "product",
  },
]

export const conditions = [
  {
    label: set_localize_content(reusable_translation?.yes, "en"),
    value: true,
  },
  {
    label: set_localize_content(reusable_translation?.no, "en"),
    value: false,
  },
]

export const languages = [
  {
    label: set_localize_content(language_translation?.english, "en"),
    value: "az",
  },
  {
    label: set_localize_content(language_translation?.georgian, "ge"),
    value: "en",
  },
]

export const statuses = [
  {
    label: set_localize_content(status_translation?.active, "en"),
    value: true,
  },
  {
    label: set_localize_content(status_translation?.inactive, "en"),
    value: false,
  },
]

export const points_type = [
  {
    label: "Order",
    value: "order",
  },
  {
    label: "Profile",
    value: "profile",
  },
  {
    label: "Event",
    value: "event",
  },
]

export const deal_type_statuses = [
  {
    label: set_localize_content(deal_type?.customer, "en"),
    value: "customer",
  },
  {
    label: set_localize_content(deal_type?.global, "en"),
    value: "global",
  },
]

export const discount_type_statuses = [
  {
    label: set_localize_content(discount_type?.percent_off, "en"),
    value: "percent_off",
  },
  {
    label: set_localize_content(discount_type?.dollar_off, "en"),
    value: "dollar_off",
  },
  {
    label: set_localize_content(discount_type?.fixed_price, "en"),
    value: "fixed_price",
  },
]

export const product_statuses = [
  {
    label: set_localize_content(product_status_translation?.enabled, "en"),
    value: true,
  },
  {
    label: set_localize_content(product_status_translation?.disabled, "en"),
    value: false,
  },
]

export const store_fault_reasons = [
  {
    label: set_localize_content(stores_details_translation?.electricity, "en"),
    value: set_localize_content(stores_details_translation?.electricity, "en"),
  },
  {
    label: set_localize_content(stores_details_translation?.gas, "en"),
    value: set_localize_content(stores_details_translation?.gas, "en"),
  },
  {
    label: set_localize_content(stores_details_translation?.internet, "en"),
    value: set_localize_content(stores_details_translation?.internet, "en"),
  },
  {
    label: set_localize_content(stores_details_translation?.renovation, "en"),
    value: set_localize_content(stores_details_translation?.renovation, "en"),
  },
  {
    label: set_localize_content(stores_details_translation?.weather, "en"),
    value: set_localize_content(stores_details_translation?.weather, "en"),
  },
  {
    label: set_localize_content(stores_details_translation?.others, "en"),
    value: set_localize_content(stores_details_translation?.others, "en"),
  },
]

export const arithmetic_operations = [
  { label: ">=", value: ">=" },
  { label: ">", value: ">" },
  { label: "=", value: "=" },
  { label: "<=", value: "<=" },
  { label: "<", value: "<" },
]

export const glovo_order_statuses = [
  {
    label: "Assigned",
    value: "created",
  },
  {
    label: "Activated",
    value: "activated",
  },
  {
    label: "Accepted",
    value: "accepted",
  },
  {
    label: "Waiting for Pickup",
    value: "waiting_for_pickup",
  },
  {
    label: "Picked",
    value: "picked",
  },
  {
    label: "Waiting for Delivery",
    value: "waiting_for_delivery",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
]
