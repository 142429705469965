import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import { Box, Drawer, useMediaQuery } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { BrowserView, MobileView } from 'react-device-detect'
import { drawerWidth } from 'store/constants'
import MenuList from './MenuList'

const Sidebar = ({ drawerOpen, drawerToggle, window }: any) => {
    const theme = useTheme() as any
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

    const drawer = (
        <>
            <BrowserView
                style={{ marginTop: 15, height: `calc(100% - 140px)` }}
            >
                <PerfectScrollbar
                    component="div"
                    style={{
                        paddingLeft: 5,
                        paddingRight: 5,
                        height: `calc(100% - 10px)`,
                        // height: "100%",
                        // overflow: "hidden scroll",
                        // overflowY: "scroll"
                    }}
                    className="side-bar-scroll"
                >
                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>

            <MobileView>
                <Box
                    sx={{
                        px: 2,
                        height: 'auto',
                    }}
                >
                    <MenuList />
                </Box>
            </MobileView>
        </>
    )

    const container =
        window !== undefined ? () => window.document.body : undefined

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                width: matchUpMd ? drawerWidth : 'auto',
                background: drawerOpen && theme.palette.dominos.dominos_red,
            }}
            aria-label="side-nav"
        >
            <Drawer
                container={container}
                variant={matchUpMd ? 'permanent' : 'temporary'}
                // variant={"permanent"}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerOpen ? drawerWidth : 'auto',
                        background: theme.palette.dominos.dominos_white,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        boxShadow : "0px 65px 25px -1px #00000033,0px 11px 5px 0px #00000024,0px 23px 10px 0px #0000001f",
                        // boxShadow:
                        //     '0px 2px 25px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f',
                        [theme.breakpoints.up('md')]: {
                            top: '65px',
                        },
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    )
}

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object,
}

export default Sidebar
