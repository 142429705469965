import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiServices from 'services/RequestHandler'
import { capitalize_string } from 'utils/helper'
import { dateFormat, timeFormatWithSec, utc_time_zone } from './constants'
import moment from 'moment'
import _ from 'lodash'

export interface ItemsState {
    all_customers: any
    all_customers_paginate: any
    export_all_customers: any
    single_customer: any
    update_customer_data: any

    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: ItemsState = {
    all_customers: [],
    all_customers_paginate: [],
    export_all_customers: [],
    single_customer: {},
    update_customer_data: {},

    activity: false,
}

export const all_customer = createAsyncThunk('/customer', async (data: any) => {
    try {
        let response = await apiServices.postFromApi(
            data.end_point,
            data.body,
            'customer'
        )
        return {
            ...response,
        }
    } catch (err) {
        console.log(err)
    }
})

export const export_customers_request = createAsyncThunk(
    '/customer/export',
    async (data: any) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'customer'
            )
            return {
                ...response,
            }
        } catch (err) {
            console.log(err)
        }
    }
)

export const get_single_customer = createAsyncThunk(
    `/customer/:id`,
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                'customer'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const update_customer = createAsyncThunk(
    'customer/update',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'customer'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const customer_slicer = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        handle_clear_form: (state: ItemsState, action: any) => {
            state.single_customer = {}
            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(all_customer.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(all_customer.fulfilled, (state, { payload }) => {
                state.all_customers_paginate = payload
                state.all_customers = payload?.data                
                state.activity = false
            })

            .addCase(export_customers_request.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(
                export_customers_request.fulfilled,
                (state, { payload }) => {
                    let export_all_customers = [] as any
                    for (
                        let index = 0;
                        index < payload?.data?.length;
                        index++
                    ) {
                        const item = payload?.data?.[index]
                        export_all_customers.push([
                            capitalize_string(
                                item?.first_name || 'Not Available'
                            ),
                            capitalize_string(
                                item?.last_name || 'Not Available'
                            ),
                            item?.phone_code ? '+' + item?.phone_code : '--',
                            item?.phone || '--',
                            _.startCase(item?.gender) || 'Not Available',
                            item?.date_of_birth
                                ? moment(item?.date_of_birth).format(dateFormat)
                                : 'Not Available',
                            item?.total_orders || '0',
                            item?.total_completed_orders || '0',
                            item?.pulse_orders || '0',
                            item?.callcenter_orders || '0',
                            item?.wolt_orders || '0',
                            item?.olo_orders || '0',
                            item?.total_cancelled_orders || '0',
                            item?.total_order_amount || '0',
                            item?.avg_order_amount || '0',
                            item?.total_user_loyalty_points || '0',
                            item?.total_user_loyalty_points_profile || '0',
                            item?.total_user_loyalty_points_redeemed || '0',
                            item?.available_points || '0',
                            _.startCase(item?.platform) || 'Not Available',
                            item?.customer_type || 'Not Available',
                            item?.created_at
                                ? moment(item?.created_at)
                                      .utcOffset(utc_time_zone)
                                      .format(
                                          `${dateFormat} ${timeFormatWithSec}`
                                      )
                                : '--',
                            item?.first_order_date
                                ? moment(item?.first_order_date)
                                      .utcOffset(utc_time_zone)
                                      .format(
                                          `${dateFormat} ${timeFormatWithSec}`
                                      )
                                : 'Not Available',
                            item?.last_order_date
                                ? moment(item?.last_order_date)
                                      .utcOffset(utc_time_zone)
                                      .format(
                                          `${dateFormat} ${timeFormatWithSec}`
                                      )
                                : 'Not Available',
                            item?.is_verified ? 'Yes' : 'No',
                            item?.created_at
                                ? moment(item?.created_at)
                                      .utcOffset(utc_time_zone)
                                      .format(
                                          `${dateFormat} ${timeFormatWithSec}`
                                      )
                                : 'Not Available',
                            item?.added_by || 'Not Available',
                            item?.updated_at
                                ? moment(item?.updated_at)
                                      .utcOffset(utc_time_zone)
                                      .format(
                                          `${dateFormat} ${timeFormatWithSec}`
                                      )
                                : 'Not Available',
                            item?.modified_by || 'Not Available',
                        ])
                    }

                    state.export_all_customers = export_all_customers
                    state.activity = false
                }
            )

            .addCase(get_single_customer.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_single_customer.fulfilled, (state, { payload }) => {
                try {
                    state.single_customer = payload?.data as any
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })

            .addCase(
                update_customer.pending,
                (state: any, { payload }: any) => {
                    state.activity = true
                }
            )

            .addCase(
                update_customer.fulfilled,
                (state: any, { payload }: any) => {
                    try {
                        state.update_customer_data = payload
                        state.activity = false
                    } catch (error) {
                        state.activity = false
                    }
                }
            )
    },
})

export const { handle_clear_form } = customer_slicer.actions

export default customer_slicer.reducer
