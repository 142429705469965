import { createSlice } from '@reduxjs/toolkit'
import config from 'config'

export interface DrawerState {
    opened: boolean
    isOpen: any
    defaultId: 'default'
    fontFamily: string
    borderRadius: number
}

const initialState: DrawerState = {
    opened: true,
    isOpen: [], // for active default menu
    defaultId: 'default',
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
}

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        handleOpen: (state: DrawerState) => {
            return {
                ...state,
                opened: true,
            }
        },

        handleClose: (state: DrawerState) => {
            return {
                ...state,
                opened: false,
            }
        },

        handleMenuClick: (state: DrawerState, action: any) => {
            return {
                ...state,
                isOpen: [action.payload],
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { handleOpen, handleClose, handleMenuClick } = drawerSlice.actions

export default drawerSlice.reducer
