import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiServices from 'services/RequestHandler'

export interface ItemsState {
    /* CATEGORY */

    all_category: any
    all_category_data: any
    create_category: any
    update_category: any
    get_category_by_id: any
    export_all_items: any

    /* SUB CATEGORY */

    all_sub_category: any
    all_sub_category_data: any
    create_sub_category: any
    update_sub_category: any
    get_sub_category_by_id: any
    export_sub_all_items: any
    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: ItemsState = {
    /* CATEGORY */

    all_category: [],
    all_category_data: [],
    create_category: {},
    update_category: {},
    get_category_by_id: {},
    export_all_items: [],

    /* SUB CATEGORY */

    all_sub_category: [],
    all_sub_category_data: [],
    create_sub_category: {},
    update_sub_category: {},
    get_sub_category_by_id: {},
    export_sub_all_items: [],
    activity: false,
}

/* CATEGORY */
export const all_categories = createAsyncThunk(
    '/category',
    async (data: any) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                data.permission_name
            )
            return {
                ...response,
            }
        } catch (err) {
            console.log(err)
        }
    }
)

export const get_category = createAsyncThunk(
    `/category/:id`,
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                'category'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const create_category = createAsyncThunk(
    '/category/add',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'category/add'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const update_category = createAsyncThunk(
    '/category/id',
    async (data: APIParams) => {
        try {
            let response = await apiServices.patchFromApi(
                data.end_point,
                data.body,
                'category'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const upload_category_active_icon = createAsyncThunk(
    'upload/image',
    async (data: any) => {
        try {
            let formData = new FormData()
            formData.append('file', data.body)
            let response = await apiServices.postFromImage(
                data.end_point,
                formData,
                'categoryimage',
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )

            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const upload_category_inactive_icon = createAsyncThunk(
    'upload/image',
    async (data: any) => {
        try {
            let formData = new FormData()
            formData.append('file', data.body)
            let response = await apiServices.postFromImage(
                data.end_point,
                formData,
                'categoryimage',
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )

            return response
        } catch (err) {
            console.log(err)
        }
    }
)

/* SUB CATEGORY */

export const all_sub_categories = createAsyncThunk(
    '/sub-category',
    async (data: any) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                data.permission_name
            )
            return {
                ...response,
            }
        } catch (err) {
            console.log(err)
        }
    }
)

export const get_sub_category = createAsyncThunk(
    `/sub_category/:id`,
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                'sub_category'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const create_sub_category = createAsyncThunk(
    '/sub_category/add',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'sub_category/add'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const update_sub_category = createAsyncThunk(
    '/sub_category/id',
    async (data: APIParams) => {
        try {
            let response = await apiServices.patchFromApi(
                data.end_point,
                data.body,
                'sub_category'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const upload_subcategory_active_icon = createAsyncThunk(
    'upload/image',
    async (data: any) => {
        try {
            let formData = new FormData()
            formData.append('file', data.body)
            let response = await apiServices.postFromImage(
                data.end_point,
                formData,
                'categoryimage',
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )

            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const upload_subcategory_inactive_icon = createAsyncThunk(
    'upload/image',
    async (data: any) => {
        try {
            let formData = new FormData()
            formData.append('file', data.body)
            let response = await apiServices.postFromImage(
                data.end_point,
                formData,
                'categoryimage',
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )

            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const category_slicer = createSlice({
    name: 'items',
    initialState,
    reducers: {
        handle_clear_form: (state: ItemsState, action: any) => {
            state.get_category_by_id = {}
            state.create_category = {}
            state.update_category = {}

            state.get_sub_category_by_id = {}
            state.create_sub_category = {}
            state.update_sub_category = {}

            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder
            /* CATEGORY */

            .addCase(all_categories.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(all_categories.fulfilled, (state, { payload }) => {
                state.all_category_data = payload?.data
                state.activity = false
            })

            .addCase(create_category.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(create_category.fulfilled, (state, { payload }) => {
                try {
                    state.create_category = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(create_category.rejected, (state, { payload }) => {
                try {
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(update_category.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(update_category.fulfilled, (state, { payload }) => {
                try {
                    state.update_category = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(get_category.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_category.fulfilled, (state, { payload }) => {
                try {
                    state.get_category_by_id = payload?.data as any
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })

            /* SUB CATEGORY */

            .addCase(all_sub_categories.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(all_sub_categories.fulfilled, (state, { payload }) => {
                state.all_sub_category_data = payload?.data
                state.activity = false
            })

            .addCase(create_sub_category.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(create_sub_category.fulfilled, (state, { payload }) => {
                try {
                    state.create_sub_category = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(create_sub_category.rejected, (state, { payload }) => {
                try {
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(update_sub_category.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(update_sub_category.fulfilled, (state, { payload }) => {
                try {
                    state.update_sub_category = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(get_sub_category.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_sub_category.fulfilled, (state, { payload }) => {
                try {
                    state.get_sub_category_by_id = payload.data as any
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })
    },
})

export const { handle_clear_form } = category_slicer.actions

export default category_slicer.reducer
