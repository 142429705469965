/* DASHBOARD */
export const dashboard_transalations = {
  daily_order_status: {
    en: "Daily Order Status",
    ge: "",
  },
  order_channel: {
    en: "Order Channel",
    ge: "",
  },
  service_type: {
    en: "Service Type",
    ge: "",
  },
  wolt_orders: {
    en: "wolt Orders",
    ge: "",
  },
  olo_orders: {
    en: "OLO Orders",
    ge: "",
  },
  glovo_orders: {
    en: "Glovo Orders",
    ge: "",
  },
  aggregator_orders: {
    en: "Aggregator Orders",
    ge: "",
  },
  pulse_orders: {
    en: "Pulse Orders",
    ge: "",
  },
  bolt_orders: {
    en: "Bolt Orders",
    ge: "",
  },
  olo: {
    en: "OLO",
    ge: "",
  },
  aggregator: {
    en: "Aggregator",
    ge: "",
  },
  pulse: {
    en: "Pulse",
    ge: "",
  },
}

/* CATEGORIES REGISTER & DETAILS */
export const category_register_translation = {
  category_heading: {
    en: "Category",
    ge: "",
  },
  category_code: {
    en: "Code",
    ge: "",
  },
  category_name_en: {
    en: "Name [ EN ]",
    ge: "",
  },
  category_name_ge: {
    en: "Name [ GE ]",
    ge: "",
  },
  category_description: {
    en: "Description",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  index: {
    en: "Index",
    ge: "",
  },
  sequence: {
    en: "Sequence",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  create_category: {
    en: "Create Category",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
    ge: "",
  },
}

export const category_details_translation = {
  create_category_heading: {
    en: "Create category",
    ge: "",
  },
  category_details_heading: {
    en: "Category Details",
    ge: "",
  },
  category_code: {
    en: "Code",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  index_no: {
    en: "Index No.",
    ge: "",
  },
  category_name_en: {
    en: "Name (En)",
    ge: "",
  },
  category_name_ge: {
    en: "Name (Ge)",
    ge: "",
  },
  category_description_en: {
    en: "Description (En)",
    ge: "",
  },
  category_deacription_ge: {
    en: "Description (Ge)",
    ge: "",
  },
  create_category: {
    en: "Create category",
    ge: "",
  },
  update_category: {
    en: "Update category",
    ge: "",
  },
  category_code_placeholder: {
    en: "Please enter category code.",
    ge: "",
  },
  category_name_en_placeholder: {
    en: "Please enter category name in EN.",
    ge: "",
  },
  category_name_ge_placeholder: {
    en: "Please enter category name in GE.",
    ge: "",
  },
  category_description_en_placeholder: {
    en: "Please enter category description in EN.",
    ge: "",
  },
  category_description_ge_placeholder: {
    en: "Please enter category description in GE.",
    ge: "",
  },
  category_created_success: {
    en: "Category created successfully.",
    ge: "",
  },
  category_updated_success: {
    en: "Category updated successfully.",
    ge: "",
  },
}

/* SUB CATEGORIES REGISTER & DETAILS */
export const sub_category_register_translation = {
  sub_category_heading: {
    en: "Sub Category",
    ge: "",
  },
  category: {
    en: "Category",
    ge: "",
  },
  category_code: {
    en: "Code",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  index: {
    en: "Index",
    ge: "",
  },
  sequence: {
    en: "Sequence",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  create_category: {
    en: "Create Sub Category",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
    ge: "",
  },
}

export const sub_category_details_translation = {
  create_sub_category_heading: {
    en: "Create sub category",
    ge: "",
  },
  sub_category_details_heading: {
    en: "Sub Category Details",
    ge: "",
  },
  sub_category_code: {
    en: "Code",
    ge: "",
  },
  index_no: {
    en: "Index No.",
    ge: "",
  },
  category_code: {
    en: "Category",
    ge: "",
  },
  sub_category_name_en: {
    en: "Name (En)",
    ge: "",
  },
  sub_category_name_ge: {
    en: "Name (Ge)",
    ge: "",
  },
  sub_category_description_en: {
    en: "Description (En)",
    ge: "",
  },
  sub_category_deacription_ge: {
    en: "Description (Ge)",
    ge: "",
  },
  is_active: {
    en: "Is active",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  create_category: {
    en: "Create sub category",
    ge: "",
  },
  update_category: {
    en: "Update sub category",
    ge: "",
  },
  sub_category_code_placeholder: {
    en: "Please enter sub category code.",
    ge: "",
  },
  category_code_placeholder: {
    en: "Please select category code.",
    ge: "",
  },
  sub_category_name_en_placeholder: {
    en: "Please enter sub category name in EN.",
    ge: "",
  },
  sub_category_name_ge_placeholder: {
    en: "Please enter sub category name in GE.",
    ge: "",
  },
  sub_category_description_en_placeholder: {
    en: "Please enter sub category description in EN.",
    ge: "",
  },
  sub_category_description_ge_placeholder: {
    en: "Please enter sub category description in GE.",
    ge: "",
  },
  sub_category_created_success: {
    en: "Subcategory created successfully.",
    ge: "",
  },
  sub_category_updated_success: {
    en: "Subcategory updated successfully.",
    ge: "",
  },
}

/* SIZE REGISTER & DETAILS */
export const sizes_translation = {
  size_heading: {
    en: "Size",
    ge: "",
  },
  size_code: {
    en: "Code",
    ge: "",
  },
  size_decription: {
    en: "Description",
    ge: "",
  },
  size_decription_en: {
    en: "Description (en)",
    ge: "",
  },
  size_decription_ge: {
    en: "Decription (ge)",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
    ge: "",
  },
  create_size: {
    en: "Create size",
    ge: "",
  },
  update_size: {
    en: "Update size",
    ge: "",
  },
  delete_size: {
    en: "Delete size",
    ge: "",
  },
  size_created_success: {
    en: "Size created successfully.",
    ge: "",
  },
  size_updated_success: {
    en: "Size updated successfully.",
    ge: "",
  },
}

export const sizes_details_translation = {
  create_size_heading: {
    en: "Create Size",
    ge: "",
  },
  size_detail_heading: {
    en: "Size Details",
    ge: "",
  },
  size_code: {
    en: "Code",
    ge: "",
  },
  category_code: {
    en: "Category",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  size_desc_en: {
    en: "Description (en)",
    ge: "",
  },
  size_desc_ge: {
    en: "Description (ge)",
    ge: "",
  },
  short_desc_en: {
    en: "Short Description (en)",
    ge: "",
  },
  short_desc_ge: {
    en: "Short Description (ge)",
    ge: "",
  },
  size_code_placeholder: {
    en: "Please enter size code",
    ge: "",
  },
  size_unit_placeholder: {
    en: "Please enter size unit",
    ge: "",
  },
  size_number_placeholder: {
    en: "Please enter size number",
    ge: "",
  },
}

/* COURIER REGISTER TRANSLATION */
export const courier_register_translation = {
  couriers: {
    en: "Couriers",
    ge: "",
  },
  couriers_per_page: {
    en: "Couriers Per Page",
    ge: "",
  },
  add_courier: {
    en: "Add Courier",
    ge: "",
  },
  update_courier: {
    en: "Update Courier",
    ge: "",
  },
  courier_id: {
    en: "Courier ID",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  mobile: {
    en: "Mobile",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  select_status: {
    en: "Select Status",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  select_store: {
    en: "Select Store",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  select_start_date: {
    en: "Select Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  select_end_date: {
    en: "Select End Date",
    ge: "",
  },
  change_password: {
    en: "Change Password",
    ge: "",
  },
  new_password: {
    en: "New Password",
    ge: "",
  },
  new_password_placeholder: {
    en: "Please enter your new password",
    ge: "",
  },
}

export const courier_table = {
  courier_id: {
    en: "Courier ID",
    ge: "",
  },
  courier_name: {
    en: "Courier Name",
    ge: "",
  },
  courier_mobile: {
    en: "Courier Mobile",
    ge: "",
  },
  store_name: {
    en: "Store Name",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  updated_at: {
    en: "Updated At",
    ge: "",
  },
  updated_by: {
    en: "Updated By",
    ge: "",
  },
  map: {
    en: "Map",
    ge: "",
  },
  manage: {
    en: "Manage",
    ge: "",
  },
  reset_password: {
    en: "Reset Password",
    ge: "",
  },
}

export const courier_information_translation = {
  courier_information: {
    en: "Courier Information",
    ge: "",
  },
  courier_id: {
    en: "Courier ID",
    ge: "",
  },
  first_name: {
    en: "First Name",
    ge: "",
  },
  last_name: {
    en: "Last Name",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  select_status: {
    en: "Select Status",
    ge: "",
  },
  mobile: {
    en: "Mobile",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  select_store: {
    en: "Select Store",
    ge: "",
  },
  password: {
    en: "Password",
    ge: "",
  },
  confirm_password: {
    en: "Confirm Password",
    ge: "",
  },
  courier_image: {
    en: "Courier Image",
    ge: "",
  },
  add_courier: {
    en: "Add Courier",
    ge: "",
  },
  update_courier: {
    en: "Update Courier",
    ge: "",
  },
}

/* ONGOING ORDERS */
export const ongoing_orders_translation = {
  ongoing_orders: {
    en: "Ongoing Orders",
    ge: "",
  },
  orders_per_page: {
    en: "Orders Per Page",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  select_status: {
    en: "Select Status",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  select_store: {
    en: "Select Store",
    ge: "",
  },
  assigned_orders: {
    en: "Assigned Orders",
    ge: "",
  },
  unassigned_orders: {
    en: "Unassigned Orders",
    ge: "",
  },
  change: {
    en: "Change",
    ge: "",
  },
  order_created: {
    en: "Order Created",
    ge: "",
  },
  no_orders_found: {
    en: "No Orders Found",
    ge: "",
  },
  order_id_copied: {
    en: "Order ID copied to clipboard!",
    ge: "",
  },
}

export const ongoing_order_details = {
  order_id: {
    en: "Order ID",
    ge: "",
  },
  courier: {
    en: "COURIER",
    ge: "",
  },
  order_created: {
    en: "Order Created",
    ge: "",
  },
  route: {
    en: "Route",
    ge: "",
  },
  order_status: {
    en: "Order Status",
    ge: "",
  },
  additional_comments: {
    en: "AddItIonal Comments",
    ge: "",
  },
  distance: {
    en: "Distance",
    ge: "",
  },
  reassign: {
    en: "Reassign",
    ge: "",
  },
  tracking: {
    en: "Tracking",
    ge: "",
  },
  change_status: {
    en: "Change Status",
    ge: "",
  },
  select_courier: {
    en: "Select Courier",
    ge: "",
  },
}

export const order_history_translation = {
  order_history: {
    en: "Order History",
    ge: "",
  },
  orders_per_page: {
    en: "Orders Per Page",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  select_status: {
    en: "Select Status",
    ge: "",
  },
  by_courier: {
    en: "By Courier",
    ge: "",
  },
  select_courier: {
    en: "Select Courier",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  select_store: {
    en: "Select Store",
    ge: "",
  },
  order_id: {
    en: "Order ID",
    ge: "",
  },
  traking: {
    en: "Tracking",
    ge: "",
  },
  route: {
    en: "Route",
    ge: "",
  },
  distance: {
    en: "Distance",
    ge: "",
  },
  price: {
    en: "Price",
    ge: "",
  },
  statuss: {
    en: "Status",
    ge: "",
  },
  creation_date: {
    en: "Creation Date",
    ge: "",
  },
  courier_name: {
    en: "Courier",
    ge: "",
  },
  review: {
    en: "Review",
    ge: "",
  },
}

/* PRODUCTS */
export const product_headings_translation = {
  pizza: {
    en: "Pizza",
    ge: "",
  },
  topping: {
    en: "Topping",
    ge: "",
  },
  crust: {
    en: "Crust",
    ge: "",
  },
  edge: {
    en: "Edge",
    ge: "",
  },
  bread: {
    en: "Bread",
    ge: "",
  },
  chicken: {
    en: "Chicken",
    ge: "",
  },
  sandwich: {
    en: "Sandwich",
    ge: "",
  },
  wrap: {
    en: "Wrap",
    ge: "",
  },
  load_potato: {
    en: "Load Potato",
    ge: "",
  },
  dessert: {
    en: "Dessert",
    ge: "",
  },
  drinks: {
    en: "Drinks",
    ge: "",
  },
  sauce: {
    en: "Sauce",
    ge: "",
  },
}

export const product_register_translation = {
  code: {
    en: "Code",
    ge: "",
  },
  sub_category: {
    en: "Sub Category",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  desc: {
    en: "Description",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  is_active: {
    en: "Is active",
    ge: "",
  },
  index: {
    en: "Index",
    ge: "",
  },
  sequence: {
    en: "Sequence",
    ge: "",
  },
  category: {
    en: "Category",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
    ge: "",
  },
}

export const product_details_translation = {
  /* PIZZA */
  is_in_home_page: {
    en: "Show On Home Page",
    ge: "",
  },
  is_other_home_page: {
    en: "Show On Others Page",
    ge: "",
  },
  create_pizza_heading: {
    en: "Create Pizza",
    ge: "",
  },
  update_pizza: {
    en: "Update Pizza",
    ge: "",
  },
  pizza_details: {
    en: "Pizza Details",
    ge: "",
  },

  /* TOPPING */

  create_topping_heading: {
    en: "Create Topping",
    ge: "",
  },
  update_topping: {
    en: "Update Topping",
    ge: "",
  },
  topping_details: {
    en: "Topping Details",
    ge: "",
  },

  /* CRUST */

  create_crust_heading: {
    en: "Create Crust",
    ge: "",
  },
  update_crust: {
    en: "Update Crust",
    ge: "",
  },
  crust_details: {
    en: "Crust Details",
    ge: "",
  },

  /* EDGE */

  create_edge_heading: {
    en: "Create Edge",
    ge: "",
  },
  update_edge: {
    en: "Update Edge",
    ge: "",
  },
  edge_details: {
    en: "Edge Details",
    ge: "",
  },

  /* BREAD */

  create_bread_heading: {
    en: "Create Bread",
    ge: "",
  },
  bread_details_heading: {
    en: "Bread Details",
    ge: "",
  },
  create_bread: {
    en: "Create Bread",
    ge: "",
  },
  update_bread: {
    en: "Update Bread",
    ge: "",
  },

  /* CHICKEN */

  create_chicken_heading: {
    en: "Create Chicken",
    ge: "",
  },
  chicken_details_heading: {
    en: "Chicken Details",
    ge: "",
  },
  create_chicken: {
    en: "Create Chicken",
    ge: "",
  },
  update_chicken: {
    en: "Update Chicken",
    ge: "",
  },

  /* SANDWICH */

  create_sandwich_heading: {
    en: "Create Sandwich",
    ge: "",
  },
  sandwich_details_heading: {
    en: "Sandwich Details",
    ge: "",
  },
  create_sandwich: {
    en: "Create Sandwich",
    ge: "",
  },
  update_sandwich: {
    en: "Update Sandwich",
    ge: "",
  },

  /* WRAP */

  create_wrap_heading: {
    en: "Create Wrap",
    ge: "",
  },
  wrap_details_heading: {
    en: "Wrap Deatils",
    ge: "",
  },
  create_wrap: {
    en: "Create Wrap",
    ge: "",
  },
  update_wrap: {
    en: "Update Wrap",
    ge: "",
  },

  /* LOAD POTATO */

  create_load_potato_heading: {
    en: "Create Load Potato",
    ge: "",
  },
  load_potato_details_heading: {
    en: "Load Poatato Details",
    ge: "",
  },
  create_load_potato: {
    en: "Create Load Potato",
    ge: "",
  },
  update_load_potato: {
    en: "Update Load Potato",
    ge: "",
  },

  /* DESSERT */

  create_dessert_heading: {
    en: "Create Dessert",
    ge: "",
  },
  dessert_details_heading: {
    en: "Dessert Details",
    ge: "",
  },
  create_dessert: {
    en: "Create Dessert",
    ge: "",
  },
  update_dessert: {
    en: "Update Dessert",
    ge: "",
  },

  /* DRINKS */

  create_drink_heading: {
    en: "Create Drink",
    ge: "",
  },
  drink_details_heading: {
    en: "Drink Details",
    ge: "",
  },
  create_drink: {
    en: "Create Drink",
    ge: "",
  },
  update_drink: {
    en: "Update Drink",
    ge: "",
  },

  /* SAUCE */

  create_sauce_heading: {
    en: "Create Sauce",
    ge: "",
  },
  sauce_details_heading: {
    en: "Sauce Details",
    ge: "",
  },
  create_sauce: {
    en: "Create Sauce",
    ge: "",
  },
  update_sauce: {
    en: "Update Sauce",
    ge: "",
  },

  /* PRODUCT */

  product_code: {
    en: "Code",
    ge: "",
  },
  osg_code: {
    en: "OSG Code",
    ge: "",
  },
  product_name_placeholder: {
    en: "Please enter product name.",
    ge: "",
  },

  product_code_placeholder: {
    en: "Please enter product code.",
    ge: "",
  },
  store_code_placeholder: {
    en: "Store code can not be empty.",
    ge: "",
  },
  category_code: {
    en: "Category",
    ge: "",
  },
  category_code_placeholder: {
    en: "Please select category.",
    ge: "",
  },
  sub_category_code: {
    en: "Sub Category",
    ge: "",
  },
  sub_category_code_placeholder: {
    en: "Please select sub category.",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  product_status_placeholder: {
    en: "Please select status.",
    ge: "",
  },
  status_placeholder: {
    en: "Please select status.",
    ge: "",
  },
  topping: {
    en: "Topping",
    ge: "",
  },
  topping_placeholder: {
    en: "Please select topping.",
    ge: "",
  },
  sizes: {
    en: "Size",
    ge: "",
  },
  sizes_placeholder: {
    en: "Please select size.",
    ge: "",
  },
  dough: {
    en: "dough",
    ge: "",
  },
  dough_placeholder: {
    en: "Please select dough.",
  },
  dough_name_placeholder: {
    en: "Please Enter Dough Name.",
    ge: "",
  },
  edge_name_placeholder: {
    en: "Please Enter Edge Name.",
    ge: "",
  },
  option_selection: {
    en: "option selection",
    ge: "",
  },
  option_select_placeholder: {
    en: "Please select option selection group.",
    ge: "",
  },
  combination: {
    en: "Combination",
    ge: "",
  },
  is_reorder: {
    en: "Is Reordered",
    ge: "",
  },
  is_reordered: {
    en: "Is Reordered",
    ge: "",
  },
  is_reordered_placeholder: {
    en: "Please select product can be reordered or not.",
    ge: "",
  },
  is_available: {
    en: "Is Available",
    ge: "",
  },
  is_available_placeholder: {
    en: "Please select product is available or not.",
    ge: "",
  },
  is_sellable: {
    en: "Is Sellable",
    ge: "",
  },
  is_sellable_placeholder: {
    en: "Please select product is sellable or not.",
    ge: "",
  },
  is_spicy: {
    en: "Is Spicy",
    ge: "",
  },
  extra_cheese: {
    en: "Extra Cheese",
    ge: "",
  },
  is_popular: {
    en: "Is Popular",
    ge: "",
  },
  is_popular_placeholder: {
    en: "Please select product is popular or not.",
    ge: "",
  },
  is_new_product: {
    en: "Is New",
    ge: "",
  },
  half_and_half: {
    en: "Is Half & Half",
    ge: "",
  },
  is_custom: {
    en: "Is Custom",
    ge: "",
  },
  is_new_product_placeholder: {
    en: "Please select product is new or not.",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  is_sold: {
    en: "Is Sold",
    ge: "",
  },
  is_default: {
    en: "Is Default",
    ge: "",
  },
  product_price: {
    en: "Price",
    ge: "",
  },
  product_price_placeholder: {
    en: "Please enter product price.",
    ge: "",
  },
  shareable_link: {
    en: "Shareable Link",
    ge: "",
  },
  stores: {
    en: "Stores",
    ge: "",
  },
  statuses: {
    en: "Statuses",
    ge: "",
  },
  stores_placeholder: {
    en: "Please select stores",
    ge: "",
  },
  service_methods: {
    en: "Service Methods",
    ge: "",
  },
  service_methods_placeholder: {
    en: "Please select service methods",
    ge: "",
  },
  product_channel: {
    en: "Product Channel",
    ge: "",
  },
  product_channel_placeholder: {
    en: "Please select product channels.",
    ge: "",
  },
  payment_methods: {
    en: "Payment Methods",
    ge: "",
  },
  payment_methods_placeholder: {
    en: "Please select payment methods.",
    ge: "",
  },
  loyalty_redeem_allowed: {
    en: "Loyality Redeem",
    ge: "",
  },
  loyalty_point_allowed: {
    en: "Loyality Points",
    ge: "",
  },
  product_name: {
    en: "Name",
    ge: "",
  },
  product_name_en: {
    en: "Name (EN)",
    ge: "",
  },
  product_name_ge: {
    en: "Name (GE)",
    ge: "",
  },
  product_desc: {
    en: "Description",
    ge: "",
  },
  english_content: {
    en: "English Content",
    ge: "",
  },
  georgian_content: {
    en: "Georgian Content",
    ge: "",
  },
  topping_price: {
    en: "Topping price",
    ge: "",
  },
  pizza_heading: {
    en: "Pizza",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  description: {
    en: "Description",
    ge: "",
  },
  media_detail: {
    en: "Media Detail",
    ge: "",
  },
  media_global: {
    en: "Media Global",
    ge: "",
  },
  web_desc: {
    en: "Web Description",
    ge: "",
  },
  web_media_detail: {
    en: "Web Media Detail",
    ge: "",
  },
  web_media_global: {
    en: "Web Media Global",
    ge: "",
  },
  mobile: {
    en: "Mobile Name",
    ge: "",
  },
  mobile_desc: {
    en: "Mobile Description",
    ge: "",
  },
  mobile_media_detail: {
    en: "Mobile Media Detail",
    ge: "",
  },
  mobile_media_global: {
    en: "Mobile Media Global",
    ge: "",
  },
  call_center_name: {
    en: "Callcenter Name",
    ge: "",
  },
  call_center_desc: {
    en: "Callcenter Description",
    ge: "",
  },
  call_center_media_detail: {
    en: "Callcenter Media Detail",
    ge: "",
  },
  call_center_media_global: {
    en: "Callcenter Media Global",
    ge: "",
  },
  create_product: {
    en: "Create Product",
    ge: "",
  },
  update_product: {
    en: "Update Product",
    ge: "",
  },
  create_product_success: {
    en: "Product created successfully.",
    ge: "",
  },
  update_product_success: {
    en: "Product updated successfully.",
    ge: "",
  },
  price_small: {
    en: "Price Small",
    ge: "",
  },
  price_medium: {
    en: "Price Medium",
    ge: "",
  },
  price_large: {
    en: "Price Large",
    ge: "",
  },
  basic: {
    en: "Basic",
    ge: "",
  },
  default: {
    en: "default",
    ge: "default",
  },
  update_crust_success: {
    en: "Crust updated successfully.",
    ge: "",
  },
  update_edge_success: {
    en: "Edge updated successfully.",
    ge: "",
  },
  create_crust_success: {
    en: "Crust created successfully.",
    ge: "",
  },
  create_edge_success: {
    en: "Edge created successfully.",
    ge: "",
  },
  create_topping_success: {
    en: "Topping created successfully.",
    ge: "",
  },
  update_topping_success: {
    en: "Topping updated successfully.",
    ge: "",
  },
  crust: {
    en: "Crust",
    ge: "",
  },
  standard_toppings: {
    en: "Standard Toppings",
    ge: "",
  },
  sizes_and_price: {
    en: "Sizes & Prices",
    ge: "",
  },
  crust_and_edges: {
    en: "Crust & edges",
    ge: "",
  },
  enter_pizza_name: {
    en: "Please Enter Pizza Name for Web",
    ge: "",
  },
  enter_pizza_description: {
    en: "Please Enter Pizza Description for Web",
    ge: "",
  },
  upload_media_detail: {
    en: "Please Upload Media Detail Image for Web",
    ge: "",
  },
  upload_media_global: {
    en: "Please Upload Media Global Image for Web",
    ge: "",
  },
  loyality_points: {
    en: "Loyality Points",
    ge: "",
  },
  loyality_redeme_allowed: {
    en: "Loyalty Redeem Allowed",
    ge: "",
  },
  loyality_points_allowed: {
    en: "Loyalty Points Allowed",
    ge: "",
  },
  product_discount_type: {
    en: "Product Discount Type",
    ge: "",
  },
  product_discount_type_placeholder: {
    en: "Select Product Discount Type",
    ge: "",
  },
  fixed_price: {
    en: "Fixed Price (Toplam)",
    ge: "",
  },
  dollar_off: {
    en: "Dollar Off ($)",
    ge: "",
  },
  percent_off: {
    en: "Percent Off (%)",
    ge: "",
  },
  discount_value: {
    en: "Discount Value",
    ge: "",
  },
  discount_value_placeholder: {
    en: "Enter Discount Value",
    ge: "",
  },

  // Get OSG Products Translation
  enter_osg_code: {
    en: "Please Enter OSG Code",
    ge: "",
  },
  select_store: {
    en: "Please Select Store",
    ge: "",
  },
  topping_removed_succuessfully: {
    en: "Selected Topping Remove Succefully.",
    ge: "",
  },
  select_topping_code: {
    en: "Select Topping Code",
    ge: "",
  },
  osg_code_not_exist: {
    en: "OSG Code is not Exist",
    ge: "",
  },
  // for osg modal
  get_pizza: {
    en: "Get PIzza",
    ge: "",
  },
  osg_code_placeholder: {
    en: "Enter OSG Code",
    ge: "",
  },
  osg_osg_code: {
    en: "OSG Code",
    ge: "",
  },
  osg_stores: {
    en: "Stores",
    ge: "",
  },
  osg_store_placeholder: {
    en: "Select Store",
    ge: "",
  },
  osg_cancel_button: {
    en: "Cancel",
    ge: "",
  },
  osg_get_button: {
    en: "Get",
    ge: "",
  },
  // osg confirmation delete modal
  delete_confirm: {
    en: "Confirm",
    ge: "",
  },
  delete_topping_heading: {
    en: "ARE YOU SURE WANT TO REMOVE THIS TOPPING?",
    ge: "",
  },
  delete_yes_button: {
    en: "Yes",
    ge: "",
  },
  // topping alert modal
  following_topping_heading: {
    en: "This Toppings do not exist in the system. Please make the following toppings first:",
    ge: "",
  },
  create_osg_topping: {
    en: "Create Topping",
    ge: "",
  },
}

export const product_group_translation = {
  /* PIZZA */
  group: {
    en: "Groups",
    ge: "",
  },
  is_in_home_page: {
    en: "Show On Home Page",
    ge: "",
  },
  is_other_home_page: {
    en: "Show On Others Page",
    ge: "",
  },
  create_group_heading: {
    en: "Create Group",
    ge: "",
  },
  update_pizza: {
    en: "Update Group",
    ge: "",
  },
  pizza_details: {
    en: "Group Details",
    ge: "",
  },

  /* TOPPING */

  create_topping_heading: {
    en: "Create Topping",
    ge: "",
  },
  update_topping: {
    en: "Update Topping",
    ge: "",
  },
  topping_details: {
    en: "Topping Details",
    ge: "",
  },

  /* CRUST */

  create_crust_heading: {
    en: "Create Crust",
    ge: "",
  },
  update_crust: {
    en: "Update Crust",
    ge: "",
  },
  crust_details: {
    en: "Crust Details",
    ge: "",
  },

  crust_placeholder: {
    en: "Select Crusts",
    ge: "",
  },
  /* EDGE */

  create_edge_heading: {
    en: "Create Edge",
    ge: "",
  },
  update_edge: {
    en: "Update Edge",
    ge: "",
  },
  edge_details: {
    en: "Edge Details",
    ge: "",
  },
  edge_placeholder: {
    en: "Select Edges",
    ge: "",
  },

  /* BREAD */

  create_bread_heading: {
    en: "Create Bread",
    ge: "",
  },
  bread_details_heading: {
    en: "Bread Details",
    ge: "",
  },
  create_bread: {
    en: "Create Bread",
    ge: "",
  },
  update_bread: {
    en: "Update Bread",
    ge: "",
  },

  /* CHICKEN */

  create_chicken_heading: {
    en: "Create Chicken",
    ge: "",
  },
  chicken_details_heading: {
    en: "Chicken Details",
    ge: "",
  },
  create_chicken: {
    en: "Create Chicken",
    ge: "",
  },
  update_chicken: {
    en: "Update Chicken",
    ge: "",
  },

  /* SANDWICH */

  create_sandwich_heading: {
    en: "Create Sandwich",
    ge: "",
  },
  sandwich_details_heading: {
    en: "Sandwich Details",
    ge: "",
  },
  create_sandwich: {
    en: "Create Sandwich",
    ge: "",
  },
  update_sandwich: {
    en: "Update Sandwich",
    ge: "",
  },

  /* WRAP */

  create_wrap_heading: {
    en: "Create Wrap",
    ge: "",
  },
  wrap_details_heading: {
    en: "Wrap Deatils",
    ge: "",
  },
  create_wrap: {
    en: "Create Wrap",
    ge: "",
  },
  update_wrap: {
    en: "Update Wrap",
    ge: "",
  },

  /* LOAD POTATO */

  create_load_potato_heading: {
    en: "Create Load Potato",
    ge: "",
  },
  load_potato_details_heading: {
    en: "Load Poatato Details",
    ge: "",
  },
  create_load_potato: {
    en: "Create Load Potato",
    ge: "",
  },
  update_load_potato: {
    en: "Update Load Potato",
    ge: "",
  },

  /* DESSERT */

  create_dessert_heading: {
    en: "Create Dessert",
    ge: "",
  },
  dessert_details_heading: {
    en: "Dessert Details",
    ge: "",
  },
  create_dessert: {
    en: "Create Dessert",
    ge: "",
  },
  update_dessert: {
    en: "Update Dessert",
    ge: "",
  },

  /* DRINKS */

  create_drink_heading: {
    en: "Create Drink",
    ge: "",
  },
  drink_details_heading: {
    en: "Drink Details",
    ge: "",
  },
  create_drink: {
    en: "Create Drink",
    ge: "",
  },
  update_drink: {
    en: "Update Drink",
    ge: "",
  },

  /* SAUCE */

  create_sauce_heading: {
    en: "Create Sauce",
    ge: "",
  },
  sauce_details_heading: {
    en: "Sauce Details",
    ge: "",
  },
  create_sauce: {
    en: "Create Sauce",
    ge: "",
  },
  update_sauce: {
    en: "Update Sauce",
    ge: "",
  },

  /* PRODUCT */

  product_code: {
    en: "Code",
    ge: "",
  },
  osg_code: {
    en: "OSG Code",
    ge: "",
  },
  product_name_placeholder: {
    en: "Please enter product name.",
    ge: "",
  },

  product_code_placeholder: {
    en: "Please enter product code.",
    ge: "",
  },
  store_code_placeholder: {
    en: "Store code can not be empty.",
    ge: "",
  },
  category_code: {
    en: "Category",
    ge: "",
  },
  category_code_placeholder: {
    en: "Please select category.",
    ge: "",
  },
  sub_category_code: {
    en: "Sub Category",
    ge: "",
  },
  sub_category_code_placeholder: {
    en: "Please select sub category.",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  product_status_placeholder: {
    en: "Please select status.",
    ge: "",
  },
  status_placeholder: {
    en: "Please select status.",
    ge: "",
  },
  topping: {
    en: "Topping",
    ge: "",
  },
  topping_placeholder: {
    en: "Please select topping.",
    ge: "",
  },
  sizes: {
    en: "sizes",
    ge: "",
  },
  sizes_placeholder: {
    en: "Please select sizes.",
    ge: "",
  },
  dough: {
    en: "dough",
    ge: "",
  },
  dough_placeholder: {
    en: "Please select dough.",
  },
  dough_name_placeholder: {
    en: "Please Enter Dough Name.",
    ge: "",
  },
  edge_name_placeholder: {
    en: "Please Enter Edge Name.",
    ge: "",
  },
  option_selection: {
    en: "option selection",
    ge: "",
  },
  option_select_placeholder: {
    en: "Please select option selection group.",
    ge: "",
  },
  combination: {
    en: "Combination",
    ge: "",
  },
  is_reorder: {
    en: "Is Reordered",
    ge: "",
  },
  is_reordered: {
    en: "Is Reordered",
    ge: "",
  },
  is_reordered_placeholder: {
    en: "Please select product can be reordered or not.",
    ge: "",
  },
  is_available: {
    en: "Is Available",
    ge: "",
  },
  is_available_placeholder: {
    en: "Please select product is available or not.",
    ge: "",
  },
  is_sellable: {
    en: "Is Sellable",
    ge: "",
  },
  is_sellable_placeholder: {
    en: "Please select product is sellable or not.",
    ge: "",
  },
  is_spicy: {
    en: "Is spicy",
    ge: "",
  },
  extra_cheese: {
    en: "Extra cheese",
    ge: "",
  },
  is_popular: {
    en: "Is Popular",
    ge: "",
  },
  is_popular_placeholder: {
    en: "Please select product is popular or not.",
    ge: "",
  },
  is_new_product: {
    en: "Is New",
    ge: "",
  },
  half_and_half: {
    en: "Is half & half",
    ge: "",
  },
  is_custom: {
    en: "Is custom",
    ge: "",
  },
  is_new_product_placeholder: {
    en: "Please select product is new or not.",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  is_sold: {
    en: "Is Sold",
    ge: "",
  },
  is_default: {
    en: "Is Default",
    ge: "",
  },
  product_price: {
    en: "Price",
    ge: "",
  },
  product_price_placeholder: {
    en: "Please enter product price.",
    ge: "",
  },
  shareable_link: {
    en: "Shareable Link",
    ge: "",
  },
  stores: {
    en: "Stores",
    ge: "",
  },
  stores_placeholder: {
    en: "Please select stores",
    ge: "",
  },
  service_methods: {
    en: "SERVICE METHODS",
    ge: "",
  },
  service_methods_placeholder: {
    en: "Please select service methods",
    ge: "",
  },
  product_channel: {
    en: "Product Channel",
    ge: "",
  },
  product_channel_placeholder: {
    en: "Please select product channels.",
    ge: "",
  },
  payment_methods: {
    en: "Payment Methods",
    ge: "",
  },
  payment_methods_placeholder: {
    en: "Please select payment methods.",
    ge: "",
  },
  loyalty_redeem_allowed: {
    en: "Loyality Redeem",
    ge: "",
  },
  loyalty_point_allowed: {
    en: "Loyality Points",
    ge: "",
  },
  product_name: {
    en: "Name",
    ge: "",
  },
  product_name_en: {
    en: "Name (EN)",
    ge: "",
  },
  product_name_ge: {
    en: "Name (GE)",
    ge: "",
  },
  product_desc: {
    en: "Description",
    ge: "",
  },
  english_content: {
    en: "English Content",
    ge: "",
  },
  georgian_content: {
    en: "Georgian Content",
    ge: "",
  },
  topping_price: {
    en: "Topping price",
    ge: "",
  },
  pizza_heading: {
    en: "Pizza",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  description: {
    en: "Description",
    ge: "",
  },
  media_detail: {
    en: "Media Detail",
    ge: "",
  },
  media_global: {
    en: "Media Global",
    ge: "",
  },
  web_desc: {
    en: "Web Description",
    ge: "",
  },
  web_media_detail: {
    en: "Web Media Detail",
    ge: "",
  },
  web_media_global: {
    en: "Web Media Global",
    ge: "",
  },
  mobile: {
    en: "Mobile Name",
    ge: "",
  },
  mobile_desc: {
    en: "Mobile Description",
    ge: "",
  },
  mobile_media_detail: {
    en: "Mobile Media Detail",
    ge: "",
  },
  mobile_media_global: {
    en: "Mobile Media Global",
    ge: "",
  },
  call_center_name: {
    en: "Callcenter Name",
    ge: "",
  },
  call_center_desc: {
    en: "Callcenter Description",
    ge: "",
  },
  call_center_media_detail: {
    en: "Callcenter Media Detail",
    ge: "",
  },
  call_center_media_global: {
    en: "Callcenter Media Global",
    ge: "",
  },
  create_product: {
    en: "Create Product",
    ge: "",
  },
  update_product: {
    en: "Update Product",
    ge: "",
  },
  create_product_success: {
    en: "Product created successfully.",
    ge: "",
  },
  update_product_success: {
    en: "Product updated successfully.",
    ge: "",
  },
  price_small: {
    en: "Price Small",
    ge: "",
  },
  price_medium: {
    en: "Price Medium",
    ge: "",
  },
  price_large: {
    en: "Price Large",
    ge: "",
  },
  basic: {
    en: "Basic",
    ge: "",
  },
  default: {
    en: "default",
    ge: "default",
  },
  update_crust_success: {
    en: "Crust updated successfully.",
    ge: "",
  },
  update_edge_success: {
    en: "Edge updated successfully.",
    ge: "",
  },
  create_crust_success: {
    en: "Crust created successfully.",
    ge: "",
  },
  create_edge_success: {
    en: "Edge created successfully.",
    ge: "",
  },
  create_topping_success: {
    en: "Topping created successfully.",
    ge: "",
  },
  update_topping_success: {
    en: "Topping updated successfully.",
    ge: "",
  },
  crust: {
    en: "Crust",
    ge: "",
  },
  standard_toppings: {
    en: "Standard Toppings",
    ge: "",
  },
  sizes_and_price: {
    en: "Sizes & Prices",
    ge: "",
  },
  crust_and_edges: {
    en: "Crust & edges",
    ge: "",
  },
}

/* USER REGISTER & DETAILS */
export const users_register_translation = {
  users_heading: {
    en: "Users",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
    ge: "",
  },
  create_user: {
    en: "Create User",
    ge: "",
  },
  select_criteria_for_user_search: {
    en: "Please select search criteria for users result.",
    ge: "",
  },
}

export const users_register_search_translation = {
  search_by_user: {
    en: "Search By User",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  user_name: {
    en: "User Name",
    ge: "",
  },
  phone: {
    en: "Phone",
    ge: "",
  },
  is_employee: {
    en: "Is Employee?",
    ge: "",
  },

  department: {
    en: "Department",
    ge: "",
  },
  designation: {
    en: "Designation",
    ge: "",
  },
  employee_type: {
    en: "Employee Type",
    ge: "",
  },
  is_manager: {
    en: "Is Manager?",
    ge: "",
  },
  line_manager: {
    en: "Line Manager",
    ge: "",
  },
  location: {
    en: "Location",
    ge: "",
  },
  name_search: {
    en: "Name",
    ge: "",
  },
  email_search: {
    en: "Email",
    ge: "",
  },
  status_search: {
    en: "Status",
    ge: "",
  },
  user_name_search: {
    en: "User Name",
    ge: "",
  },
  phone_search: {
    en: "Phone",
    ge: "",
  },
  is_employee_search: {
    en: "Is Employee?",
    ge: "",
  },
  department_search: {
    en: "Department Name",
    ge: "",
  },
  designation_search: {
    en: "Designation Name",
    ge: "",
  },
  employee_type_search: {
    en: "Employee Type",
    ge: "",
  },
  is_manager_search: {
    en: "Is Manager?",
    ge: "",
  },
  line_manager_search: {
    en: "Line Manager",
    ge: "",
  },
  location_search: {
    en: "Location",
    ge: "",
  },
}

export const users_details_translation = {
  create_user_heading: {
    en: "Create User",
    ge: "",
  },
  update_user_heading: {
    en: "User Details",
    ge: "",
  },
  general_information: {
    en: "General Details",
    ge: "",
  },
  assign_roles: {
    en: "Roles",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  status_placeholder: {
    en: "Please select user status.",
    ge: "",
  },
  first_name: {
    en: "First Name",
    ge: "",
  },
  first_name_palceholder: {
    en: "Please enter user first name.",
    ge: "",
  },
  last_name: {
    en: "Last Name",
    ge: "",
  },
  last_name_placeholder: {
    en: "Please enter user last name.",
    ge: "",
  },
  user_name: {
    en: "User Name",
    ge: "",
  },
  user_name_placeholder: {
    en: "Please enter username.",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  email_placeholder: {
    en: "Please enter user email.",
    ge: "",
  },
  validate_email: {
    en: "Please validate email.",
    ge: "",
  },
  password: {
    en: "Password",
    ge: "",
  },
  confirm_password: {
    en: "Confirm Password",
    ge: "",
  },
  password_not_match: {
    en: "Passwords not matched.",
    ge: "",
  },
  password_length: {
    en: "Password must be longer than or equal to 8 characters.",
    ge: "",
  },
  password_valid: {
    en: "minimum 8 characters, a capital letter and a special character",
    ge: "",
  },
  phone: {
    en: "Phone",
    ge: "",
  },
  store_placeholder: {
    en: "Please Select Store",
    ge: "",
  },
  stores_input: {
    en: "Stores",
    ge: "",
  },
  phone_placeholder: {
    en: "Please enter user phone number.",
    ge: "",
  },
  create_user: {
    en: "Create User",
    ge: "",
  },
  update_user: {
    en: "Update User",
    ge: "",
  },
  delete_user: {
    en: "Delete User",
    ge: "",
  },
  select_role: {
    en: "Select Role",
    ge: "",
  },
  select_role_placeholder: {
    en: "Please select role.",
    ge: "",
  },
  assign_role: {
    en: "Assign Role",
    ge: "",
  },
  user_created_success: {
    en: "User created successfully.",
    ge: "",
  },
  user_updated_success: {
    en: "User updated successfully.",
    ge: "",
  },
  user_deleted_success: {
    en: "User deleted successfully.",
    ge: "",
  },
  role_name: {
    en: "Name",
    ge: "",
  },
  role_description: {
    en: "Description",
    ge: "",
  },
  picture: {
    en: "Picture",
    ge: "",
  },
}

/* ROLES REGISTER & DETAILS */
export const roles_register_translation = {
  roles_heading: {
    en: "Roles",
    ge: "",
  },
  role_name: {
    en: "Role Name",
    ge: "",
  },
  role_description: {
    en: "Role Description",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
    ge: "",
  },
  create_role: {
    en: "Create Role",
    ge: "",
  },
}

export const roles_register_search_translation = {
  search_by_role: {
    en: "Search By Roles",
    ge: "",
  },
  role_name: {
    en: "Name",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  modules: {
    en: "Modules",
    ge: "",
  },
  user_name: {
    en: "User Name",
    ge: "",
  },
  role_name_search: {
    en: "Role Name",
    ge: "",
  },
  role_status_search: {
    en: "Role Status",
    ge: "",
  },
  modules_search: {
    en: "Modules",
    ge: "",
  },
  user_name_search: {
    en: "User Name",
    ge: "",
  },
}

export const roles_details_translation = {
  create_role_heading: {
    en: "Create Role",
    ge: "",
  },
  update_role_heading: {
    en: "Role Details",
    ge: "",
  },
  role_name: {
    en: "Name",
    ge: "",
  },
  role_name_placeholder: {
    en: "Please enter role name.",
    ge: "",
  },
  role_description: {
    en: "Description",
    ge: "",
  },
  role_description_placeholder: {
    en: "Please enter role description.",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  status_placeholder: {
    en: "Please select status.",
    ge: "",
  },
  create_role: {
    en: "Create Role",
    ge: "",
  },
  update_role: {
    en: "Update Role",
    ge: "",
  },
  delete_role: {
    en: "Delete Role",
    ge: "",
  },
  permissions: {
    en: "Permissions",
    ge: "",
  },
  assigned_users: {
    en: "Assigned Users",
    ge: "",
  },
  module: {
    en: "Module",
    ge: "",
  },
  full_access: {
    en: "Full Access",
    ge: "",
  },
  view: {
    en: "View",
    ge: "",
  },
  create: {
    en: "Create",
    ge: "",
  },
  edit: {
    en: "Edit",
    ge: "",
  },
  delete: {
    en: "Delete",
    ge: "",
  },
  download: {
    en: "Download",
    ge: "",
  },
  print: {
    en: "Print",
    ge: "",
  },
  create_role_success: {
    en: "Role created successfully.",
    ge: "",
  },
  update_role_success: {
    en: "Role updated successfully.",
    ge: "",
  },
  delete_role_success: {
    en: "Role deleted successfully.",
    ge: "",
  },
  serial_number: {
    en: "S. No.",
    ge: "",
  },
  user_name: {
    en: "User Name",
    ge: "",
  },
  user_email: {
    en: "User Email",
    ge: "",
  },
}

/* STORE REGISTER & DETAILS */
export const stores_register_translation = {
  stores_heading: {
    en: "Stores",
    ge: "",
  },
  store_code: {
    en: "Store Code",
    ge: "",
  },
  store_name: {
    en: "Store Name",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  ip_address: {
    en: "IP Address",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
    ge: "",
  },
  create_store: {
    en: "Create Store",
    ge: "",
  },
}

export const stores_register_search_translation = {
  search_by_stores: {
    en: "Search By Stores",
    ge: "",
  },
  store_code: {
    en: "Store Code",
    ge: "",
  },
  store_name: {
    en: "Store Name",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  ip_address: {
    en: "IP Address",
    ge: "",
  },
  store_code_search: {
    en: "Store Code",
    ge: "",
  },
  store_name_search: {
    en: "Store Name",
    ge: "",
  },
  store_status_search: {
    en: "Status",
    ge: "",
  },
  ip_address_search: {
    en: "IP Address",
    ge: "",
  },
}

export const stores_details_translation = {
  create_store_heading: {
    en: "Create Store",
    ge: "",
  },
  update_store_heading: {
    en: "Store Details",
    ge: "",
  },
  store_code: {
    en: "Code",
    ge: "",
  },
  store_code_placeholder: {
    en: "Please enter store code.",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  is_active_placeholder: {
    en: "Please select store is active or not.",
    ge: "",
  },
  get_price: {
    en: "Get Price",
    ge: "",
  },
  get_price_placeholder: {
    en: "Please select get price from this store or not.",
    ge: "",
  },
  store_open_at: {
    en: "Open At",
    ge: "",
  },
  store_open_at_placeholder: {
    en: "Please select store opening time.",
    ge: "",
  },
  store_lat_lng_placeholder: {
    en: "please enter latitude longitude.",
    ge: "",
  },
  store_close_at: {
    en: "Close At",
    ge: "",
  },
  store_close_at_placeholder: {
    en: "Please select store closing time.",
    ge: "",
  },
  store_delivery_till: {
    en: "Delivery Till",
    ge: "",
  },
  store_delivery_till_placeholder: {
    en: "Please select store delivery time.",
    ge: "",
  },
  store_name_en: {
    en: "Name (EN)",
    ge: "",
  },
  store_name_en_placeholder: {
    en: "Please enter store name in EN.",
    ge: "",
  },
  store_name_ge: {
    en: "Name (GE)",
    ge: "",
  },
  store_name_ge_placeholder: {
    en: "Please enter store name in GE.",
    ge: "",
  },
  store_desc_en: {
    en: "Description (EN)",
    ge: "",
  },
  store_desc_en_placeholder: {
    en: "Please enter store description in EN.",
    ge: "",
  },
  store_desc_ge: {
    en: "Description (GE)",
    ge: "",
  },
  store_desc_ge_placeholder: {
    en: "Please enter store description in GE.",
    ge: "",
  },
  store_image: {
    en: "Store Image",
    ge: "",
  },
  address_information: {
    en: "Address Details",
    ge: "",
  },
  city: {
    en: "City",
    ge: "",
  },
  city_placeholder: {
    en: "Please select store city.",
    ge: "",
  },
  phone: {
    en: "Phone",
    ge: "",
  },
  phone_placeholder: {
    en: "Please enter store phone no.",
    ge: "",
  },
  longitude: {
    en: "Longitude",
    ge: "",
  },
  longitude_placeholder: {
    en: "Please enter store longitude.",
    ge: "",
  },
  latitude: {
    en: "Latitude",
    ge: "",
  },
  latitude_placeholder: {
    en: "Please enter store latitude.",
    ge: "",
  },
  address_en: {
    en: "Address (EN)",
    ge: "",
  },
  address_en_placeholder: {
    en: "Please enter store address in EN.",
    ge: "",
  },
  address_ge: {
    en: "Address (GE)",
    ge: "",
  },
  address_ge_placeholder: {
    en: "Please enter store address in GE.",
    ge: "",
  },
  other_information: {
    en: "Other Details",
    ge: "",
  },
  redirect_information: {
    en: "Store Redirection Details",
    ge: "",
  },
  store_territory: {
    en: "Store territory",
    ge: "",
  },
  start_date: {
    en: "Start date & time",
    ge: "",
  },
  end_date: {
    en: "End date & time",
    ge: "",
  },
  redirect_store: {
    en: "Redirect store",
    ge: "",
  },
  reason: {
    en: "Reason",
    ge: "",
  },
  start_date_filter: {
    en: "Start date cannot be greater than end date.",
    ge: "",
  },
  end_date_filter: {
    en: "End date cannot be less than start date.",
    ge: "",
  },
  store_ip_address: {
    en: "IP Address",
    ge: "",
  },
  store_private_ip_address: {
    en: "Private IP Address",
    ge: "",
  },
  store_ip_address_placeholder: {
    en: "Please enter store ip address.",
    ge: "",
  },
  store_private_ip_address_placeholder: {
    en: "Please enter store private ip address.",
    ge: "",
  },
  payment_type: {
    en: "Payment Type",
    ge: "",
  },
  delivery_type: {
    en: "Delivery Type",
    ge: "",
  },
  channel: {
    en: "Channels",
    ge: "",
  },
  channel_placeholder: {
    en: "Select Channel",
    ge: "",
  },
  payment_type_placeholder: {
    en: "Select Payment type.",
    ge: "",
  },
  delivery_type_placeholder: {
    en: "Select Delivery Type.",
    ge: "",
  },
  pulse_version: {
    en: "Pulse version",
    ge: "",
  },
  pulse_version_placeholder: {
    en: "Please enter store pulse version.",
    ge: "",
  },
  is_redirect: {
    en: "Is Redirect",
    ge: "",
  },
  redirect_start_date: {
    en: "Redirect Start Date",
    ge: "",
  },
  redirect_end_date: {
    en: "Redirect End Date",
    ge: "",
  },
  redirect_to: {
    en: "Redirect to",
    ge: "",
  },
  is_fault: {
    en: "Is Fault",
    ge: "",
  },
  create_store: {
    en: "Create Store",
    ge: "",
  },
  update_store: {
    en: "Update Store",
    ge: "",
  },
  electricity: {
    en: "Electricity",
    ge: "",
  },
  gas: {
    en: "Gas",
    ge: "",
  },
  internet: {
    en: "Internet",
    ge: "",
  },
  renovation: {
    en: "Renovation",
    ge: "",
  },
  weather: {
    en: "Weather",
    ge: "",
  },
  others: {
    en: "Others",
    ge: "",
  },
  create_store_success: {
    en: "Store created successfully.",
    ge: "",
  },
  update_store_success: {
    en: "Store updated successfully.",
    ge: "",
  },
}

/* SMS MANAGEMENT TRANSLATION */
export const sms_management_translation = {
  sms_management: {
    en: "SMS Management",
    ge: "",
  },
  local: {
    en: "Local",
    ge: "",
  },
  international: {
    en: "International",
    ge: "",
  },
  update_information: {
    en: "Update Information",
    ge: "",
  },
  allowed_countries: {
    en: "Allowed Countries",
    ge: "",
  },
  not_allowed_countries: {
    en: "Blocked Countries",
    ge: "",
  },
}

export const local_sms_management_translation = {
  services: {
    en: "Services",
    ge: "",
  },
  select_services: {
    en: "Select SMS Services",
    ge: "",
  },
  sms_api: {
    en: "SMS Api",
    ge: "",
  },
  select_sms_api: {
    en: "Select Web SMS Api",
    ge: "",
  },
  action: {
    en: "Action",
    ge: "",
  },
  sms_api_action: {
    en: "Select Web SMS Api Action",
    ge: "",
  },
  select_andorid_sms_api: {
    en: "Select Andorid SMS Api",
    ge: "",
  },
  select_andorid_sms_api_action: {
    en: "Select Android SMS Api Action",
    ge: "",
  },
  select_ios_sms_api: {
    en: "Select iOS SMS Api",
    ge: "",
  },
  select_ios_sms_api_action: {
    en: "Select iOS SMS Api Action",
    ge: "",
  },
  select_callcenter_sms_api: {
    en: "Select Callcenter SMS Api",
    ge: "",
  },
  select_callcenter_sms_api_action: {
    en: "Select Callcenter Api Action",
    ge: "",
  },
}

export const int_sms_management_translation = {
  services: {
    en: "Services",
    ge: "",
  },
  select_services: {
    en: "Select SMS Services",
    ge: "",
  },
  sms_api: {
    en: "SMS Api",
    ge: "",
  },
  select_sms_api: {
    en: "Select Web SMS Api",
    ge: "",
  },
  select_android_sms_api: {
    en: "Select Android SMS Api",
    ge: "",
  },
  action: {
    en: "Action",
    ge: "",
  },
  sms_api_action: {
    en: "Select Web SMS Api Action",
    ge: "",
  },
  msg_type: {
    en: "Message Type",
    ge: "",
  },
  country: {
    en: "Country",
    ge: "",
  },
  select_country: {
    en: "Select Country",
    ge: "",
  },
  interval_duration: {
    en: "Interval Duration (min)",
    ge: "",
  },
  request_per_interval: {
    en: "Request Per Interval",
    ge: "",
  },
  select_andorid_sms_api: {
    en: "Select Andorid SMS Api",
    ge: "",
  },
  select_andorid_sms_api_action: {
    en: "Select Android SMS Api Action",
    ge: "",
  },

  select_ios_sms_api: {
    en: "Select iOS SMS Api",
    ge: "",
  },
  select_ios_sms_api_action: {
    en: "Select iOS SMS Api Action",
    ge: "",
  },
  select_callcenter_sms_api: {
    en: "Select Callcenter SMS Api",
    ge: "",
  },
  select_callcenter_sms_api_action: {
    en: "Select Callcenter Api Action",
    ge: "",
  },
}

/* CUSTOMER TRANSALATION */
export const customer_transalation = {
  customer: {
    en: "Customer",
    ge: "",
  },
  customer_details: {
    en: "Customer Details",
    ge: "",
  },
  update_customer: {
    en: "Update Customer",
    ge: "",
  },
  first_name: {
    en: "First Name",
    ge: "",
  },
  last_name: {
    en: "Last Name",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  country_code: {
    en: "Country Code",
    ge: "",
  },
  phone_code: {
    en: "Phone Code",
    ge: "",
  },
  phone: {
    en: "Phone",
    ge: "",
  },
  banned: {
    en: "Banned",
    ge: "",
  },
  birthdate: {
    en: "Birth Date",
    ge: "",
  },
  gender: {
    en: "Gender",
    ge: "",
  },
  agent_note: {
    en: "Agent Note",
    ge: "",
  },
  register_on: {
    en: "Register On",
    ge: "",
  },
  first_order_date: {
    en: "First Order Date",
    ge: "",
  },
  last_order_date: {
    en: "Last Order Date",
    ge: "",
  },
  otp: {
    en: "OTP",
    ge: "",
  },
  otp_time: {
    en: "OTP Time",
    ge: "",
  },
  otp_channel: {
    en: "OTP Channel",
    ge: "",
  },
  flag: {
    en: "Flag",
    ge: "",
  },
  profile_pic: {
    en: "Profile Picture",
    ge: "",
  },
  start_date_filter: {
    en: "Start date cannot be greater than end date.",
    ge: "",
  },
  end_date_filter: {
    en: "End date cannot be less than start date.",
    ge: "",
  },
  addresses: {
    en: "Addresses",
    ge: "",
  },
  address: {
    en: "Address",
    ge: "",
  },
  call_center_orders: {
    en: "Callcenter Orders",
    ge: "",
  },
  wolt_orders: {
    en: "Wolt Orders",
    ge: "",
  },
  olo_orders: {
    en: "OLO Orders",
    ge: "",
  },
  cancelled_orders: {
    en: "Cancelled Orders",
    ge: "",
  },
  completed_order_amount: {
    en: "Completed Order Amount",
    ge: "",
  },
  avg_order_amount: {
    en: "Avg Order Amount",
    ge: "",
  },
  total_points: {
    en: "Total Points",
    ge: "",
  },
  profile_points: {
    en: "Profile Points",
    ge: "",
  },
  redeemed_points: {
    en: "Redeemed Points",
    ge: "",
  },
  available_points: {
    en: "Available Points",
    ge: "",
  },
  platform: {
    en: "Platform",
    ge: "",
  },
  customer_type: {
    en: "Customer Type",
    ge: "",
  },
  register_date: {
    en: "Register Date",
    ge: "",
  },
  verified: {
    en: "Verified",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
  },
  orders: {
    en: "Orders",
    ge: "",
  },
  total_orders: {
    en: "Total Orders",
    ge: "",
  },
  completed_orders: {
    en: "Completed Order",
    ge: "",
  },
  order_last_140_days: {
    en: "Orders In Last 140 Days",
    ge: "",
  },
  pulse_orders: {
    en: "Pulse Orders",
    ge: "",
  },
  callcenter_orders: {
    en: "Callcenter Orders",
    ge: "",
  },
  cancelled_order: {
    en: "Cancelled Orders",
    ge: "",
  },
  completed_orders_amount: {
    en: "Completed Orders Amount",
    ge: "",
  },
  picture: {
    en: "Picture",
    ge: "",
  },
  order_number: {
    en: "Order Number",
    ge: "",
  },
  product_name: {
    en: "Product Name",
    ge: "",
  },
  payment: {
    en: "Payment",
    ge: "",
  },
  order_date: {
    en: "Order Date",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  channel: {
    en: "Channel",
    ge: "",
  },
  service_method: {
    en: "Service Method",
    ge: "",
  },
  amount: {
    en: "Amount",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  title: {
    en: "Title",
    ge: "",
  },
  street_address: {
    en: "Street Address",
    ge: "",
  },
  building: {
    en: "Building",
    ge: "",
  },
  street_name: {
    en: "Street Name",
    ge: "",
  },
  street_no: {
    en: "Street No",
    ge: "",
  },
  floor: {
    en: "Floor",
    ge: "",
  },
  city: {
    en: "City",
    ge: "",
  },
  note: {
    en: "Note",
    ge: "",
  },
  no_orders_found: {
    en: "No Orders Found",
    ge: "",
  },
  no_address_found: {
    en: "No Address Found",
    ge: "",
  },
  coupons: {
    en: "Coupons",
    ge: "",
  },
  coupon_code: {
    en: "Coupon Code",
    ge: "",
  },
  type: {
    en: "Type",
    ge: "",
  },
  assigned_date: {
    en: "Assigned Date",
    ge: "",
  },
  active: {
    en: "Active",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  redeem: {
    en: "Redeem",
    ge: "",
  },
  redeemed: {
    en: "Redeemed",
    ge: "",
  },
  expired: {
    en: "Expired",
    ge: "",
  },
  earned_points: {
    en: "Earned Points",
    ge: "",
  },
  total_earned_points: {
    en: "Total Earned Points",
    ge: "",
  },
  available_point: {
    en: "Available Points",
    ge: "",
  },
  loyality_points: {
    en: "Loyality Points",
    ge: "",
  },
  total_redeemed_points: {
    en: "Total Redeemed Points",
    ge: "",
  },
  redeemed_date: {
    en: "Redeemed Date",
    ge: "",
  },
  loyality_points_redeemed: {
    en: "Loyality Points Redeemed",
    ge: "",
  },
  assign_coupon: {
    en: "Assign Coupon",
    ge: "",
  },
  select_deal_type: {
    en: "Select Deal Type",
    ge: "",
  },
  select_deals: {
    en: "Select Deals",
    ge: "",
  },
  start_date_time: {
    en: "Start Date & Time",
    ge: "",
  },
  end_date_time: {
    en: "End Date & Time",
    ge: "",
  },
  no_coupons_found: {
    en: "No Coupon Found",
    ge: "",
  },
  no_earned_points_found: {
    en: "No Earned Points Found",
    ge: "",
  },
  no_redeemed_points_found: {
    en: "No Redeemed Points Found",
    ge: "",
  },
  logout_customer: {
    en: "Logout Customer",
    ge: "",
  },
}

export const olo_orders_filteration = {
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
}

/* ORDER TRANSALATION */
export const order_filter_translation = {
  order_no: {
    en: "Order No.",
    ge: "",
  },
  pulse_id: {
    en: "Pulse ID",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  mobile: {
    en: "Mobile",
    ge: "",
  },
  stores: {
    en: "Stores",
    ge: "",
  },
  placeholder_stores: {
    en: "Select Stores",
    ge: "",
  },
  channel: {
    en: "Channel",
    ge: "",
  },
  placeholder_channel: {
    en: "Select Channel",
    ge: "",
  },
  statuses: {
    en: "Statuses",
    ge: "",
  },
  placeholder_statuses: {
    en: "Select Statuses",
    ge: "",
  },
  payment_methods: {
    en: "Payment Methods",
    ge: "",
  },
  placeholder_payment_methods: {
    en: "Select Payment Methods",
    ge: "",
  },
  service_methods: {
    en: "Service Methods",
    ge: "",
  },
  placeholder_service_methods: {
    en: "Select Service Methods",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  search_criteria: {
    en: "Please select or enter any search criteria.",
    ge: "",
  },
  orders_exported_successfully: {
    en: "Orders data exported successfully.",
    ge: "",
  },
  no_orders_found: {
    en: "No Orders Found.",
    ge: "",
  },
}

export const awaiting_filter_translation = {
  order_no: {
    en: "Temp. Order No.",
    ge: "",
  },
  placeholder_order_no: {
    en: "i.e. 60516835",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  mobile: {
    en: "Mobile",
    ge: "",
  },
  stores: {
    en: "Stores",
    ge: "",
  },
  placeholder_stores: {
    en: "Select Stores",
    ge: "",
  },
  channel: {
    en: "Channel",
    ge: "",
  },
  placeholder_channel: {
    en: "Select Channel",
    ge: "",
  },
  statuses: {
    en: "Statuses",
    ge: "",
  },
  placeholder_statuses: {
    en: "Select Statuses",
    ge: "",
  },
  service_methods: {
    en: "Service Methods",
    ge: "",
  },
  placeholder_service_methods: {
    en: "Select Service Methods",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  search_criteria: {
    en: "Please select or enter any search criteria.",
    ge: "",
  },
}

export const order_transalation = {
  olo_orders: {
    en: "OLO Orders",
    ge: "",
  },
  wolt_orders: {
    en: "wolt Orders",
    ge: "",
  },
  pulse_orders: {
    en: "Pulse Orders",
    ge: "",
  },
  bolt_orders: {
    en: "Bolt Orders",
    ge: "",
  },
  glovo_orders: {
    en: "Glovo Orders",
    ge: "",
  },
  order_no: {
    en: "Order No",
    ge: "",
  },
  pulse_id: {
    en: "Pulse Id",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  mobile: {
    en: "Mobile",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  channels: {
    en: "Channels",
    ge: "",
  },
  web: {
    en: "Web",
    ge: "",
  },
  ios: {
    en: "iOS",
    ge: "",
  },
  android: {
    en: "Android",
    ge: "",
  },
  call_center: {
    en: "Call Center",
    ge: "",
  },
  service_methods: {
    en: "Service Methods",
    ge: "",
  },
  delivery: {
    en: "Delivery",
    ge: "",
  },
  take_away: {
    en: "Take Away",
    ge: "",
  },
  dine_in: {
    en: "Dine In",
    ge: "",
  },
  payment_methods: {
    en: "Payment Methods",
    ge: "",
  },
  cash: {
    en: "Cash",
    ge: "",
  },
  card: {
    en: "Card",
    ge: "",
  },
  online: {
    en: "Online",
    ge: "",
  },
  statuses: {
    en: "Statuses",
    ge: "",
  },
  pending: {
    en: "Pending",
    ge: "",
  },
  preparing: {
    en: "Preparing",
    ge: "",
  },
  baking: {
    en: "Baking",
    ge: "",
  },
  quality_check: {
    en: "Quality Check",
    ge: "",
  },
  almost_ready: {
    en: "Almost Ready",
    ge: "",
  },
  on_its_way: {
    en: "On It's Way",
    ge: "",
  },
  deliverd_completed: {
    en: "Delivered/Completed",
    ge: "",
  },
  cancelled: {
    en: "Cancelled",
    ge: "",
  },
  future: {
    en: "Future",
    ge: "",
  },
  bad: {
    en: "Bad",
    ge: "",
  },
  abondoned: {
    en: "Abondoned",
    ge: "",
  },
  flags: {
    en: "Flags",
    ge: "",
  },
  todo: {
    en: "To-Do",
    ge: "",
  },
  critical: {
    en: "Critical",
    ge: "",
  },
  complete: {
    en: "Complete",
    ge: "",
  },
  start_data: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  order_date: {
    en: "Order Date",
    ge: "",
  },
  total_amount: {
    en: "Total Amount",
    ge: "",
  },
  order_status: {
    en: "Order Status",
    ge: "",
  },
  payment_status: {
    en: "Payment Status",
    ge: "",
  },
  channel_report: {
    en: "Channel Report",
    ge: "",
  },
  service_type: {
    en: "Service Type",
    ge: "",
  },
  managed_by: {
    en: "Managed By",
    ge: "",
  },
  expected_delivery_date: {
    en: "Expected Delivery Date",
    ge: "",
  },
  completed_cancelled_at: {
    en: "Completed At / Cancelled At",
    ge: "",
  },
  rider_name: {
    en: "Rider Name",
    ge: "",
  },
  complete_via_rider: {
    en: "Complete via Rider",
    ge: "",
  },
  complete_via_pulse: {
    en: "Complete via PULSE",
    ge: "",
  },
  estimated_delivery: {
    en: "Estimated Delivery Duration",
    ge: "",
  },
  actual_delivery: {
    en: "Actual Delivey Duration",
    ge: "",
  },
  modified_by_pulse: {
    en: "Modified By Pulse",
    ge: "",
  },
  products: {
    en: "Products",
    ge: "",
  },
  device_model: {
    en: "Device Model",
    ge: "",
  },
  app_release: {
    en: "App Release",
    ge: "",
  },
  code: {
    en: "Code",
    ge: "",
  },
  opening_hour: {
    en: "Opening Hour",
    ge: "",
  },
  closing_hour: {
    en: "Closing Hour",
    ge: "",
  },
  store_name: {
    en: "Store Name",
    ge: "",
  },
  phone: {
    en: "Phone",
    ge: "",
  },
  customer: {
    en: "Customer",
    ge: "",
  },
  customer_information: {
    en: "Customer Information",
    ge: "",
  },
  store_information: {
    en: "Store Information",
    ge: "",
  },
  change_store: {
    en: "Change Store",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  total_orders: {
    en: "Total Orders",
    ge: "",
  },
  completed_orders: {
    en: "Completed Orders",
    ge: "",
  },
  address: {
    en: "Address",
    ge: "",
  },
  order_placed_by: {
    en: "Order Placed By",
    ge: "",
  },
  order_channel: {
    en: "Order Channel",
    ge: "",
  },
  delivery_type: {
    en: "Delivery Type",
    ge: "",
  },
  payment_type: {
    en: "Payment Type",
    ge: "",
  },
  order_note: {
    en: "Order Note",
    ge: "",
  },
  cancel_message: {
    en: "Cancel Message",
    ge: "",
  },
  pulse_error: {
    en: "Pulse Error",
    ge: "",
  },
  refund_payment: {
    en: "Refund Payment",
    ge: "",
  },
  order_time: {
    en: "Order Time",
    ge: "",
  },
  order_id: {
    en: "Order Id",
    ge: "",
  },
  cancelled_at: {
    en: "Cancelled At",
    ge: "",
  },
  completed_at: {
    en: "Completed At",
    ge: "",
  },
  receipt_print: {
    en: "Receipt Print",
    ge: "",
  },
  order_confirmed_date: {
    en: "Order Confirmed Date",
    ge: "",
  },
  cancel_receipt: {
    en: "Cancel Receipt",
    ge: "",
  },
  flag: {
    en: "Flag",
    ge: "",
  },
  reorder: {
    en: "Reorder",
    ge: "",
  },
  order_information: {
    en: "Order Information",
    ge: "",
  },
  rider_information: {
    en: "Rider Information",
    ge: "",
  },
  rider_id: {
    en: "Rider Id",
    ge: "",
  },
  complete_from_app: {
    en: "Complete From App",
    ge: "",
  },
  rider_mobile_no: {
    en: "Rider Mobile No",
    ge: "",
  },
  deliver_at: {
    en: "Deliver At",
    ge: "",
  },
  reward: {
    en: "Reward",
    ge: "",
  },
  late_order_coupon: {
    en: "Late Order Coupon",
    ge: "",
  },
  new_customer_coupon: {
    en: "New Customer Coupon",
    ge: "",
  },
  redeem_points: {
    en: "Redeem Points",
    ge: "",
  },
  earn_points: {
    en: "Earn Points",
    ge: "",
  },
  order_details: {
    en: "Order Details",
    ge: "",
  },
  product_image: {
    en: "Product Image",
    ge: "",
  },
  product_code: {
    en: "Product Code",
    ge: "",
  },
  product_name: {
    en: "Product Name",
    ge: "",
  },
  quantity: {
    en: "Quantity",
    ge: "",
  },
  size: {
    en: "Size",
    ge: "",
  },
  price: {
    en: "Price",
    ge: "",
  },
  coupon_applied: {
    en: "Coupon Applied",
    ge: "",
  },
  item_details: {
    en: "Details",
    ge: "",
  },
  item_changes: {
    en: "Changes",
    ge: "",
  },
  topping_types: {
    standard: {
      en: "Standard Toppings",
      ge: "",
    },
    extra: {
      en: "Extra Toppings",
      ge: "",
    },
  },
  order_feedback: {
    en: "Order Feedback",
    ge: "",
  },
  order_comment: {
    en: "Order Comment",
    ge: "",
  },
  rating: {
    en: "Ratings",
    ge: "",
  },
}

export const deal_transalation = {
  deals: {
    en: "Coupons",
    ge: "",
  },
  add_deal: {
    en: "Add Coupon",
    ge: "",
  },
  code: {
    en: "Code",
    ge: "",
  },
  deal_name: {
    en: "Coupon Name",
    ge: "",
  },
  deal_expiry_date: {
    en: "Coupon Expiry Date",
    ge: "",
  },
  is_combined: {
    en: "Is Combined",
    ge: "",
  },
  is_active_olo: {
    en: "Is Active (OLO)",
    ge: "",
  },
  is_active_pulse: {
    en: "Is Active (Pulse)",
    ge: "",
  },
  sequence: {
    en: "Sequence",
    ge: "",
  },
  sequence_cc: {
    en: "Sequence (Call Center)",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  updated_at: {
    en: "Updated At",
    ge: "",
  },
  updated_by: {
    en: "Updated By",
    ge: "",
  },
  coupons: {
    en: "Coupons",
    ge: "",
  },
  coupon_detail: {
    en: "Coupon Details",
    ge: "",
  },
  deal_information: {
    en: "Coupon Information",
    ge: "",
  },
  deal_code: {
    en: "Coupon Code",
    ge: "",
  },
  deal_code_placeholder: {
    en: "Please Enter Deal Code",
    ge: "",
  },
  deal_is_active: {
    en: "Is Active",
    ge: "",
  },
  campaign_filter: {
    en: "Filter Campaign",
    ge: "",
  },
  deal_is_sold: {
    en: "Is Sold",
    ge: "",
  },
  deal_type: {
    en: "Type",
    ge: "",
  },
  deal_type_placeholder: {
    en: "Please Select Type",
    ge: "",
  },
  make_combine_coupon: {
    en: "Make Combine Coupons",
    ge: "",
  },
  get_active_coupons: {
    en: "Get Active Coupons From Pulse",
    ge: "",
  },
  coupon_name: {
    en: "Coupon Name",
    ge: "",
  },
  coupon_expiry_date: {
    en: "Coupon Expiry Date",
    ge: "",
  },
  create_deal: {
    en: "Create Coupon",
    ge: "",
  },
  update_deal: {
    en: "Update Coupon",
    ge: "",
  },
  is_sold: {
    en: "Is Sold",
    ge: "",
  },
  type: {
    en: "Type",
    ge: "",
  },
  customer: {
    en: "Customer",
    ge: "",
  },
  global: {
    en: "Global",
    ge: "",
  },
  stores: {
    en: "Stores",
    ge: "",
  },
  stores_placeholder: {
    en: "Please Select Stores",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  start_time: {
    en: "Start Time",
    ge: "",
  },
  end_time: {
    en: "End Time",
    ge: "",
  },
  is_startting_from: {
    en: "Is Starting From",
    ge: "",
  },
  is_upsell: {
    en: "Is Upsell Deal",
    ge: "",
  },
  is_special: {
    en: "Is Special Deal",
    ge: "",
  },
  shareable_link: {
    en: "Shareable Link",
    ge: "",
  },
  days: {
    en: "Days",
    ge: "",
  },
  georgian_content: {
    en: "Georgian Content",
    ge: "",
  },
  english_content: {
    en: "English Content",
    ge: "",
  },
  deal_products: {
    en: "Coupon Items",
    ge: "",
  },
  deal_redeemed_points: {
    en: "Coupon Redeemed Points",
    ge: "",
  },
  deal_is_available: {
    en: "Is Available",
    ge: "",
  },
  default_category: {
    en: "Default Category",
    ge: "",
  },
  default_size: {
    en: "Default Size",
    ge: "",
  },
  required_quantity: {
    en: "Required Quantity",
    ge: "",
  },
  regular_price: {
    en: "Regular Price",
    ge: "",
  },
  minimum_price: {
    en: "Minimum Price",
    ge: "",
  },
  maximum_price: {
    en: "Maximum Price",
    ge: "",
  },
  customers: {
    en: "Customers",
    ge: "",
  },
  order_no: {
    en: "Order Number",
    ge: "",
  },
  order_date: {
    en: "Order Date",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  mobile: {
    en: "Mobile",
    ge: "",
  },
  total_amount: {
    en: "Total Amount",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  order_channel: {
    en: "Order Channel",
    ge: "",
  },
  service_type: {
    en: "Service Type",
    ge: "",
  },
  is_new_customer: {
    en: "Is New Customer",
    ge: "",
  },
  update_coupon: {
    en: "Update Coupon",
    ge: "",
  },
  item_category: {
    en: "Category",
    ge: "",
  },
  item_sub_category: {
    en: "Sub Category",
    ge: "",
  },
  item_size: {
    en: "Size",
    ge: "",
  },
  item_product: {
    en: "Product",
    ge: "",
  },
  item_reg_price: {
    en: "Reg Price",
    ge: "",
  },
  item_min_price: {
    en: "Min Price",
    ge: "",
  },
  item_max_price: {
    en: "Max Discount",
    ge: "",
  },
  item_not_found: {
    en: "NO COUPON ITEMS FOUND",
    ge: "",
  },
  total_deal_items: {
    en: "Total Deal Items",
    ge: "",
  },
  max_quantity: {
    en: "Max Quantity",
    ge: "",
  },
  max_discount: {
    en: "Max Discount",
    ge: "",
  },
  reg_price_required: {
    en: "Regular Price is Required",
    ge: "",
  },
  min_price_required: {
    en: "Minimum Price is Required",
    ge: "",
  },
  max_discount_required: {
    en: "Max Discount is Required",
    ge: "",
  },
  max_quantity_required: {
    en: "Max Quantity is Required",
    ge: "",
  },
  discount_type: {
    en: "Discount Type",
    ge: "",
  },
  discount_value: {
    en: "Discount Value",
    ge: "",
  },
  discount_type_required: {
    en: "Discount Type is Required",
    ge: "",
  },
  discount_value_required: {
    en: "Discount Value is Required",
    ge: "",
  },
  deal_created: {
    en: "Coupon Created Successfully.",
    ge: "",
  },
  deal_updated: {
    en: "Coupon Updated Successfully.",
    ge: "",
  },
  no_products_found: {
    en: "No Target Products Found.",
    ge: "",
  },
  no_valid_products_found: {
    en: "No Valid Products Found.",
    ge: "",
  },
  is_in_home_page: {
    en: "Show On Home Page",
    ge: "",
  },
  is_reorder: {
    en: "Is Reorder",
    ge: "",
  },
  product_count: {
    en: "Product Count",
    ge: "",
  },
  maximum: {
    en: "Maximum",
    ge: "",
  },
}

/* LOGIN & USER PROFILE */
export const login_translation = {
  welcome: {
    en: "Welcome, Please Login Into Your Account",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  email_placeholder: {
    en: "Please enter your email.",
    ge: "",
  },
  email_must_email: {
    en: "Email must be an email.",
    ge: "",
  },
  password: {
    en: "Password",
    ge: "",
  },
  password_placeholder: {
    en: "Please enter your password.",
    ge: "",
  },
  incorrect_email_password: {
    en: "Incorrect email or password.",
    ge: "",
  },
  cannot_able_to_login: {
    en: "We can't able to login your user right now.",
    ge: "",
  },
  forgot_password: {
    en: "Forgot Password?",
    ge: "",
  },
  login: {
    en: "Login",
    ge: "",
  },
  login_successfully: {
    en: "You are logged in successfully.",
    ge: "",
  },
}

export const reset_password_translation = {
  password: {
    en: "Password",
    ge: "",
  },
  password_placeholder: {
    en: "Please enter password",
    ge: "",
  },
  confirm_password: {
    en: "Confirm Password",
    ge: "",
  },
  confirm_password_placeholder: {
    en: "Please enter confirm password",
    ge: "",
  },
  welcome: {
    en: "Welcome, Reset Your Password",
    ge: "",
  },
  paasword_placeholder: {
    en: "Please enter your password.",
    ge: "",
  },
  password_change_success: {
    en: "Password changed successfully.",
    ge: "",
  },
}

export const forgot_password_translation = {
  welcome: {
    en: "Welcome, Recover Your Account",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  email_placeholder: {
    en: "Please enter your email.",
    ge: "",
  },
  sign_in: {
    en: "Sign in?",
    ge: "",
  },
  send_request: {
    en: "Send Request",
    ge: "",
  },
  email_sent_successfully: {
    en: "Email has been sent successfully.",
    ge: "",
  },
  password_change_success: {
    en: "Password changed successfully.",
    ge: "",
  },
}

export const user_profile_translation = {
  user_profile_heading: {
    en: "Profile",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  status_placeholder: {
    en: "Please select user status.",
    ge: "",
  },
  first_name: {
    en: "First Name",
    ge: "",
  },
  first_name_placeholder: {
    en: "Please enter your first name.",
    ge: "",
  },
  last_name: {
    en: "Last Name",
    ge: "",
  },
  last_name_placeholder: {
    en: "Please enter your last name.",
    ge: "",
  },
  user_name: {
    en: "User Name",
    ge: "",
  },
  user_name_placeholder: {
    en: "Please enter your username.",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  email_placeholder: {
    en: "Please enter your email.",
    ge: "",
  },
  old_password_placeholder: {
    en: "Please enter your old password.",
    ge: "",
  },
  new_password_placeholder: {
    en: "Please enter new password.",
    ge: "",
  },
  new_confirm_password_placeholder: {
    en: "Please enter confirm password.",
  },
  password_not_match: {
    en: "Passwords and confirm password did not matched.",
  },
  phone: {
    en: "Phone",
    ge: "",
  },
  phone_placeholder: {
    en: "Please enter your phone.",
    ge: "",
  },
  update_profile: {
    en: "Update Profile",
    ge: "",
  },
  reset_password: {
    en: "Reset Password",
    ge: "",
  },
  reset_password_message: {
    en: "If you want to reset your password, then you can reset it from here.",
    ge: "",
  },
  old_password: {
    en: "Old Password",
    ge: "",
  },
  confirm_password: {
    en: "Confirm Password",
    ge: "",
  },
  new_password: {
    en: "New Password",
    ge: "",
  },
}

export const banner_register_translation = {
  banner_heading: {
    en: "Banners",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  type: {
    en: "Type",
    ge: "",
  },
  product_link: {
    en: "Product Link",
    ge: "",
  },
  deal_link: {
    en: "Deal Link",
    ge: "",
  },
  sequence: {
    en: "Sequence",
    ge: "",
  },
  deal_code: {
    en: "Deal Code",
    ge: "",
  },
  is_active: {
    en: "Is Active",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  banner_image: {
    en: "Banner Image",
    ge: "",
  },
  product_channel: {
    web: "Web",
    ios: "Ios",
    android: "Android",
    call_center: "Call Center",
  },
  create_banner_heading: {
    en: "Create Banner",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
}

export const banner_details_translation = {
  banner_details_heading: {
    en: "Banner Details",
    ge: "",
  },
  create_banner: {
    en: "Create Banner",
    ge: "",
  },
  update_banner: {
    en: "Update Banner",
    ge: "",
  },
  select: {
    en: "Select",
    ge: "",
  },
  coupon: {
    en: "Coupon",
    ge: "",
  },
  select_coupon: {
    en: "Select Coupon",
    ge: "",
  },
  select_coupon_placeholder: {
    en: "Please select coupon.",
    ge: "",
  },
  select_category: {
    en: "Select Category",
    ge: "",
  },
  select_category_placeholder: {
    en: "Please select category.",
    ge: "",
  },
  product: {
    en: "Product",
    ge: "",
  },
  select_product: {
    en: "Select Product",
    ge: "",
  },
  select_product_placeholder: {
    en: "Please select product.",
    ge: "",
  },
  link: {
    en: "Link",
    ge: "",
  },
  link_placeholder: {
    en: "Please enter link.",
    ge: "",
  },
  link_not_valid: {
    en: "Enter a valid url.",
    gr: "",
  },
  banner_code: {
    en: "Code",
    ge: "",
  },
  banner_code_placeholder: {
    en: "Please enter banner code.",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  status_placeholder: {
    en: "Please select banner status.",
    ge: "",
  },
  banner_channel: {
    en: "Banner Channel",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  start_date_placeholder: {
    en: "Please select start date.",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  end_date_placeholder: {
    en: "Please select end date.",
    ge: "",
  },
  media_files_en: {
    en: "Media Files EN",
    ge: "",
  },
  media_files_ge: {
    en: "Media Files GE",
    ge: "",
  },
  media_global_en: {
    en: "Media Global EN",
    ge: "",
  },
  media_global_en_mobile: {
    en: "Media Global EN Mobile",
    ge: "",
  },
  media_global_ge: {
    en: "Media Global GE",
    ge: "",
  },
  media_global_ge_mobile: {
    en: "Media Global GE Mobile",
    ge: "",
  },
  start_date_filter: {
    en: "Start date cannot be greater than end date.",
    ge: "",
  },
  end_date_filter: {
    en: "End date cannot be less than start date.",
    ge: "",
  },
  url_not_allowed: {
    en: "Other Web URL is not allowed.",
    ge: "",
  },
  pizza_selection: {
    en: "This selection is only for pizza.",
    ge: "",
  },
  create_banner_success: {
    en: "Banner Created Successfully.",
    ge: "",
  },
  update_banner_success: {
    en: "Banner Updated Successfully.",
  },
}

/* ERROR 401 & 404 */
export const error_401_translation = {
  error_401_heading: {
    en: "Error 401",
    ge: "",
  },
  not_authorized: {
    en: "401 Not Authorized",
    ge: "",
  },
  no_permission_message: {
    en: "You don't have permission to access this module.",
    ge: "",
  },
}

export const error_404_translation = {
  error_404_heading: {
    en: "Error 404",
    ge: "",
  },
  page_not_found: {
    en: "404 Page Not Found",
    ge: "",
  },
  no_page_found_message: {
    en: "Sorry, but the requested page is not found.",
    ge: "",
  },
}

/* DATATABLE & SEARCH FILTER */
export const datatable_translation = {
  search: {
    en: "Search...",
    ge: "",
  },
  filter_type: {
    en: "Filter Type",
    ge: "",
  },
  total: {
    en: "Total",
    ge: "",
  },
  filtered: {
    en: "Filtered",
    ge: "",
  },
  records: {
    en: "Records",
    ge: "",
  },
  serial_number: {
    en: "S. No.",
    ge: "",
  },
  no_columns_found: {
    en: "No columns found",
    ge: "",
  },
  no_records_found: {
    en: "No records found",
    ge: "",
  },
  one_per_page: {
    en: "1 / Page",
    ge: "",
  },
  ten_per_page: {
    en: "10 / Page",
    ge: "",
  },
  twenty_five_per_page: {
    en: "25 / Page",
    ge: "",
  },
  fifty_per_page: {
    en: "50 / Page",
    ge: "",
  },
  hundred_per_page: {
    en: "100 / Page",
    ge: "",
  },
  showing: {
    en: "Showing",
    ge: "",
  },
  of: {
    en: "of",
    ge: "",
  },
}

export const search_filters_translation = {
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  duration: {
    en: "Duration",
    ge: "",
  },
  time_duration: {
    en: "Please select time duration",
    ge: "",
  },
  period: {
    en: "Period",
    ge: "",
  },
  time_period: {
    en: "Please select time period",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  select_stores: {
    en: "Please select stores",
    ge: "",
  },
  all_time_records: {
    en: "All Time Records",
    ge: "",
  },
  this_year: {
    en: "This Year",
    ge: "",
  },
  this_quarter: {
    en: "This Quarter",
    ge: "",
  },
  this_month: {
    en: "This Month",
    ge: "",
  },
  this_week: {
    en: "This Week",
    ge: "",
  },
  today: {
    en: "Today",
    ge: "",
  },
  last_7_days: {
    en: "Last 7 Days",
    ge: "",
  },
  yesterday: {
    en: "Yesterday",
    ge: "",
  },
  previous_week: {
    en: "Previous Week",
    ge: "",
  },
  previous_month: {
    en: "Previous Month",
    ge: "",
  },
  custom_date: {
    en: "Custom Date",
    ge: "",
  },
  daily: {
    en: "Daily",
    ge: "",
  },
  weekly: {
    en: "Weekly",
    ge: "",
  },
  monthly: {
    en: "Monthly",
    ge: "",
  },
  yearly: {
    en: "Yearly",
    ge: "",
  },
}

/* ACTIVITY LOGS & COMMUNICATION */
export const activity_logs_translation = {
  activity_logs: {
    en: "Activity Log",
    ge: "",
  },
  communication: {
    en: "Communication",
    ge: "",
  },
  no_logs_found: {
    en: "No Logs Found",
    ge: "",
  },
  no_communication_found: {
    en: "No Communication Found",
    ge: "",
  },
}

export const comments_translation = {
  comments: {
    en: "Comments",
    ge: "",
  },
  comments_placeholder: {
    en: "Please enter your comment.",
    ge: "",
  },
  add_comment: {
    en: "Add a comment",
    ge: "",
  },
}

/* UPLOAD FILE */
export const upload_file = {
  upload_file: {
    en: "Upload File",
    ge: "",
  },
  remover_file: {
    en: "Remove File",
  },
}

/* SERVICE METHODS */
export const service_methods_translation = {
  dine_in: {
    en: "Dine in",
    ge: "",
  },
  delivery: {
    en: "Delivery",
    ge: "",
  },
  takeaway: {
    en: "Takeaway",
    ge: "",
  },
}

/* COUPON DAYS */
export const coupon_days = {
  coupon_monday: {
    en: "Monday",
    ge: "",
  },
  coupon_tuesday: {
    en: "Tuesday",
    ge: "",
  },
  coupon_wednesday: {
    en: "Wednesday",
    ge: "",
  },
  coupon_thursday: {
    en: "Thursday",
    ge: "",
  },
  coupon_friday: {
    en: "Friday",
    ge: "",
  },
  coupon_saturday: {
    en: "Saturday",
    ge: "",
  },
  coupon_sunday: {
    en: "Sunday",
    ge: "",
  },
}

/* CHANNELS */
export const channel_name_translation = {
  ios: {
    en: "iOS",
    ge: "",
  },
  android: {
    en: "Android",
    ge: "",
  },
  callcenter: {
    en: "Callcenter",
    ge: "",
  },
  web: {
    en: "Web",
    ge: "",
  },
  mobile: {
    en: "Mobile",
    ge: "",
  },
}

export const updated_channel_name_translation = {
  ios: {
    en: "iOS",
    ge: "",
  },
  android: {
    en: "Android",
    ge: "",
  },
  callcenter: {
    en: "Call Center",
    ge: "",
  },
  web: {
    en: "Web",
    ge: "",
  },
  pulse: {
    en: "Pulse",
    ge: "",
  },
}

/* PAYMENT METHODS */
export const payment_methods_translation = {
  all_payment_methods_translation: {
    en: "Cash on Delivery | Credit or Debit Card | Card on Delivery",
    ge: "",
  },
  cash_on_delivery: {
    en: "Cash",
    ge: "",
  },
  credit_or_debit_card: {
    en: "Online",
    ge: "",
  },
  card_on_delivery: {
    en: "Terminal",
    ge: "",
  },
}

/* DELIVERY METHODS */
export const delivery_methods_translation = {
  delivery: {
    en: "Delivery",
    ge: "",
  },
  takeaway: {
    en: "Takeaway",
    ge: "",
  },
  dinein: {
    en: "Dinein",
    ge: "",
  },
}

/* LANGUAGE */
export const language_translation = {
  georgian: {
    en: "Georgian",
    ge: "",
  },
  english: {
    en: "English",
    ge: "",
  },
}

/* STATUS & PRODUCT STATUS */
export const status_translation = {
  active: {
    en: "Active",
    ge: "",
  },
  inactive: {
    en: "Inactive",
    ge: "",
  },
}

export const deal_type = {
  customer: {
    en: "Customer",
    ge: "",
  },
  global: {
    en: "Global",
    ge: "",
  },
}

export const discount_type = {
  percent_off: {
    en: "Percent Off",
    ge: "",
  },
  dollar_off: {
    en: "Dollar Off",
    ge: "",
  },
  fixed_price: {
    en: "Fixed Price",
    ge: "",
  },
}

export const product_status_translation = {
  enabled: {
    en: "Active",
    ge: "",
  },
  disabled: {
    en: "Inactive",
    ge: "",
  },
}

/* REUSABLE TRANSLATION */
export const reusable_translation = {
  all: {
    en: "All",
    ge: "",
  },
  export: {
    en: "Export",
    ge: "",
  },
  back_to_all: {
    en: "Back to All",
    ge: "",
  },
  get_latest_records: {
    en: "Get Latest Records",
    ge: "",
  },
  print: {
    en: "Print",
    ge: "",
  },
  edit: {
    en: "Edit",
    ge: "",
  },
  yes: {
    en: "Yes",
    ge: "",
  },
  reset_password: {
    en: "Reset Password",
    ge: "",
  },
  no: {
    en: "No",
    ge: "",
  },
  and: {
    en: "AND",
    ge: "",
  },
  or: {
    en: "OR",
    ge: "",
  },
  start_date_filter: {
    en: "Start date cannot be greater than end date.",
    ge: "",
  },
  end_date_filter: {
    en: "End date cannot be less than start date.",
    ge: "",
  },
  search: {
    en: "Search",
    ge: "",
  },
  clear: {
    en: "Clear",
    ge: "",
  },
  upload: {
    en: "Upload",
    ge: "",
  },
  remove: {
    en: "Remove",
    ge: "",
  },
  index_no: {
    en: "Index No.",
    ge: "",
  },
  details: {
    en: "Details",
    ge: "",
  },
  create: {
    en: "Create",
    ge: "Create",
  },
  cancel_order: {
    en: "Cancel Order",
    ge: "Cancel Order",
  },
  cancel_message: {
    en: "Please Select Cancellation Reason",
    ge: "",
  },
  general: {
    en: "General",
    ge: "",
  },
}

export const promo_code_list = {
  promo_code: {
    en: "Promo Code",
    ge: "",
  },
  promo_code_type: {
    en: "Promo Code Type",
    ge: "",
  },
  discount_type: {
    en: "Discount Type",
    ge: "",
  },
  discount_amount: {
    en: "Discount Amount",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  customer_count: {
    en: "Customer Count",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  updated_at: {
    en: "Updated At",
    ge: "",
  },
  updated_by: {
    en: "Updated By",
    ge: "",
  },
  promo_codes: {
    en: "Promo Codes",
    ge: "",
  },
  add_promo_code: {
    en: "Add Promo Code",
    ge: "",
  },
}

export const promo_code_details = {
  create_promo_code: {
    en: "Create Promo Code",
    ge: "",
  },
  promo_code_details: {
    en: "Promo Code Details",
    ge: "",
  },
  promo_code: {
    en: "Promo Code",
    ge: "",
  },
  promo_code_placeholder: {
    en: "Enter Promo Code",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  status_placeholder: {
    en: "Select Status",
    ge: "",
  },
  promo_code_type: {
    en: "Promo Code Type",
    ge: "",
  },
  promo_code_type_placeholder: {
    en: "Select Promo Code Type",
    ge: "",
  },
  category: {
    en: "Category",
    ge: "",
  },
  category_placeholder: {
    en: "Select Category",
    ge: "",
  },
  product_sub_category: {
    en: "Product Sub Category",
    ge: "",
  },
  select_sub_category: {
    en: "Select Product Sub Category",
    ge: "",
  },
  product_sizes: {
    en: "Product Sizes",
    ge: "",
  },
  product_sizes_placeholder: {
    en: "Select Product Sizes",
    ge: "",
  },
  products: {
    en: "Products",
    ge: "Select Products",
  },
  select_products: {
    en: "Select Products",
    ge: "",
  },
  stores: {
    en: "Stores",
    ge: "",
  },
  select_stores: {
    en: "Select Stores",
    ge: "",
  },
  channel: {
    en: "Channel",
    ge: "",
  },
  select_channel: {
    en: "Select Channel",
    ge: "",
  },
  payment_method: {
    en: "Payment Method",
    ge: "",
  },
  select_payment_method: {
    en: "Select Payment Method",
    ge: "",
  },
  delivery_method: {
    en: "Delivery Method",
    ge: "",
  },
  select_delivery_method: {
    en: "Select Delivery Method",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  select_start_date: {
    en: "Select Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  select_end_date: {
    en: "Select End Date",
    ge: "",
  },
  start_time: {
    en: "Start Time",
    ge: "",
  },
  select_start_time: {
    en: "Select Start Time",
    ge: "",
  },
  end_time: {
    en: "End Time",
    ge: "",
  },
  select_end_time: {
    en: "Select End Time",
    ge: "",
  },
  min_order_price: {
    en: "Min Order Price [ Before Discount ]",
    ge: "",
  },
  min_order_price_placeholder: {
    en: "Enter Min Order Price [ Before Discount ]",
    ge: "",
  },
  max_order_price: {
    en: "Max Order Price [ Before Discount ]",
    ge: "",
  },
  max_order_price_placeholder: {
    en: "Enter Max Order Price [ Before Discount ]",
    ge: "",
  },
  min_order_quantity: {
    en: "Min Product Quantity",
    ge: "",
  },
  min_order_quantity_placeholder: {
    en: "Enter Min Product Quantity",
    ge: "",
  },
  max_order_quantity: {
    en: "Max Product Quantity",
    ge: "",
  },
  max_order_quantity_placeholder: {
    en: "Enter Max Product Quantity",
    ge: "",
  },
  max_user_per_account: {
    en: "Max Usage Per Account",
    ge: "",
  },
  max_customers_allowed: {
    en: "Max Orders Allowed",
    ge: "",
  },
  recurring_frequency: {
    en: "Recurring Frequency",
    ge: "",
  },
  select_recurring_frequency: {
    en: "Select Recurring Frequency",
    ge: "",
  },
  discount_type: {
    en: "Discount Type",
    ge: "",
  },
  select_discount_type: {
    en: "Select Discount Type",
    ge: "",
  },
  discount_percent: {
    en: "Discount ( % )",
    ge: "",
  },
  discount_fixed: {
    en: "Fixed Price Amount",
    ge: "",
  },
  discount_dollar: {
    en: "Dollar Off Amount",
    ge: "",
  },
  allowed_other_coupons: {
    en: "Allowed Other Coupons",
    ge: "",
  },
  apply_promo_code: {
    en: "Apply Promo Code On",
    ge: "",
  },
  allowed_customers: {
    en: "Allowed Customers",
    ge: "",
  },
  upload_csv: {
    en: "Upload CSV",
    ge: "",
  },
  enter_customer_numbers: {
    en: "Enter Customer Numbers",
    ge: "",
  },
  save: {
    en: "Save",
    ge: "",
  },
  update: {
    en: "Update",
    ge: "",
  },
  en_content: {
    en: "English Content",
    ge: "",
  },
  ge_content: {
    en: "Georgian Content",
    ge: "",
  },
  customers: {
    en: "Customers",
    ge: "",
  },
  en_description_for_web: {
    en: "Description for Web",
    ge: "",
  },
  en_description_for_mobile: {
    en: "Description for Mobile",
    ge: "",
  },
  en_description_for_callcenter: {
    en: "Description for Callcenter",
    ge: "",
  },

  en_placeholder_description_for_web: {
    en: "Enter Description for Web",
    ge: "",
  },
  en_placeholder_description_for_mobile: {
    en: "Enter Description for Mobile",
    ge: "",
  },
  en_placeholder_description_for_callcenter: {
    en: "Enter Description for Callcenter",
    ge: "",
  },
  ge_description_for_web: {
    en: "Description for Web",
    ge: "",
  },
  ge_description_for_mobile: {
    en: "Description for Mobile",
    ge: "",
  },
  ge_description_callcenter: {
    en: "Description for Callcenter",
    ge: "",
  },
  ge_placeholder_description_for_web: {
    en: "Enter Description for Web",
    ge: "",
  },
  ge_placeholder_description_for_mobile: {
    en: "Enter Description for Mobile",
    ge: "",
  },
  ge_placeholder_description_callcenter: {
    en: "Enter Description for Callcenter",
    ge: "",
  },
  total_customers: {
    en: "total Customers",
    ge: "",
  },
  created_succefully: {
    en: "Promo Code Created Successfully.",
    ge: "",
  },
  updated_successfully: {
    en: "Promo Code Updated Successfully.",
    ge: "",
  },
  requrring_duration: {
    en: "Recurring Duration",
    ge: "",
  },
  enter_no_of_days: {
    en: "Enter No. of Days",
    ge: "",
  },
  not_allowed_category: {
    en: "Not Allowed Category",
    ge: "",
  },
  not_allowed_subcategory: {
    en: "Not Allowed Subcategory",
    ge: "",
  },
  not_allowed_product_sizes: {
    en: "Not Allowed Product Sizes",
    ge: "",
  },
  not_allowed_products: {
    en: "Not Allowed Products",
    ge: "",
  },
  allowed_more_than_max: {
    en: "Allowed More Than Max Quantity",
    ge: "",
  },
  // create promo code translation
  enter_promo_code_create: {
    en: "Please Enter a Promo Code.",
    ge: "",
  },
  select_status_for: {
    en: "Please Select a Status",
    ge: "",
  },
  select_promo_code_type: {
    en: "Please Select a Promo Code Type.",
    ge: "",
  },
  select_categoires_for_promo: {
    en: "Please Select Categories For Product Specific Promo Code.",
    ge: "",
  },
  select_subcategoires_for_promo: {
    en: "Please Select Sub Categories For Product Specific Promo Code",
    ge: "",
  },
  select_sizes_for_promo: {
    en: "Please Select Sizes For Product Specific Promo Code",
    ge: "",
  },
  select_product_for_promo: {
    en: "Please Select Products For Product Specific Promo Code",
    ge: "",
  },
  select_discount_type_for_promo: {
    en: "Please Select Discount Type.",
    ge: "",
  },
  enter_discount_for_promo: {
    en: "Please Enter a Discount Value.",
    ge: "",
  },
  select_stores_for_promo: {
    en: "Please Select Stores For Promo Code.",
    ge: "",
  },
  select_service_method_for_promo: {
    en: "Please Select Service Method For Promo Code.",
    ge: "",
  },
  select_product_channel_for_promo: {
    en: "Please Select Product Channel For Promo Code.",
    ge: "",
  },
  select_payment_method_for_promo: {
    en: "Please Select Payment Method For Promo Code.",
    ge: "",
  },
  min_order_price_for_promo: {
    en: "Please Enter Minimum Order Price.",
    ge: "",
  },
  max_order_price_for_promo: {
    en: "Please Enter Maximum Order Price.",
    ge: "",
  },
  min_max_not_greater_for_promo: {
    en: "Minimum Price Cannot Be Greater Than Maximum Price.",
    ge: "",
  },
  min_product_quantity_for_promo: {
    en: "Please Enter Minimum Product Quantity.",
    ge: "",
  },
  max_product_quantity_for_promo: {
    en: "Please Enter Maximum Product Quantity.",
    ge: "",
  },
  min_max_product_quantity_for_promo: {
    en: "Minimum Product Quantity Cannot Be Greater Than Maximum Product Quantity.",
    ge: "",
  },
  recurring_frequency_for_promo: {
    en: "Please Select Recurring Frequency.",
    ge: "",
  },
  recurring_duration_for_promo: {
    en: "Please Enter Recurring Days.",
    ge: "",
  },
  start_date_for_promo: {
    en: "Please Select Start Date.",
    ge: "",
  },
  end_date_for_promo: {
    en: "Please Select End Date.",
    ge: "",
  },
  start_date_end_date_not_equal: {
    en: "Start Date & End Date Should Not Be Equal.",
    ge: "",
  },
  enter_customer_numbers_for_promo: {
    en: "Please Enter Customer Number To Allow Them.",
    ge: "",
  },
  promo_usage_per_account_for_promo: {
    en: "Please Enter Promo Code Usage Per Account.",
    ge: "",
  },
  max_customer_for_promo: {
    en: "Please Enter Maximum Orders Allowed",
    ge: "",
  },
  enter_web_description_for_promo: {
    en: "Enter Web Description for Web in En Content.",
    ge: "",
  },
}

export const promo_customers = {
  s_no: {
    en: "S.No",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  mobile: {
    en: "Mobile",
    ge: "",
  },
  new_customer: {
    en: "New Customer",
    ge: "",
  },
  order_date: {
    en: "Order Date",
    ge: "",
  },
  order_number: {
    en: "Order Number",
    ge: "",
  },
  order_status: {
    en: "Order Status",
    ge: "",
  },
  delivery_type: {
    en: "Delivery Type",
    ge: "",
  },
  channel: {
    en: "Channel",
    ge: "",
  },
  store_name: {
    en: "Store Name",
    ge: "",
  },
  payment_method: {
    en: "Payment Method",
    ge: "",
  },
  total_amount: {
    en: "Total Amount",
    ge: "",
  },
  discounted_amount: {
    en: "Discounted Amount",
    ge: "",
  },
  payment_amount: {
    en: "Payment Amount",
    ge: "",
  },
}

export const jobs_transaltions = {
  jobs: {
    en: "Jobs",
    ge: "",
  },
  create_job: {
    en: "Create Job",
    ge: "",
  },
  update_job: {
    en: "Update Job",
    ge: "",
  },
  post: {
    en: "Post",
    ge: "",
  },
  post_en: {
    en: "Post [ EN ]",
    ge: "",
  },
  address: {
    en: "Address",
    ge: "",
  },
  store: {
    en: "Store",
    ge: "",
  },
  post_ge: {
    en: "Post [ GE ]",
    ge: "",
  },
  desc: {
    en: "Description",
    ge: "",
  },
  status: {
    en: "Status",
    ge: "",
  },
  created_at: {
    en: "Created At",
    ge: "",
  },
  created_by: {
    en: "Created By",
    ge: "",
  },
  modified_at: {
    en: "Modified At",
    ge: "",
  },
  modified_by: {
    en: "Modified By",
    ge: "",
  },
  candidates: {
    en: "Cadidates",
    ge: "",
  },
  job_details: {
    en: "Job Details",
    ge: "",
  },
  name: {
    en: "Name",
    ge: "",
  },
  resume: {
    en: "Resume",
    ge: "",
  },
  job_title: {
    en: "Job Title",
    ge: "",
  },
  submitted_at: {
    en: "Submitted at",
    ge: "",
  },
  download: {
    en: "Download",
    ge: "",
  },
  download_resume: {
    en: "Download Resume",
    ge: "",
  },
  first_name: {
    en: "First Name",
    ge: "",
  },
  sur_name: {
    en: "Sur Name",
    ge: "",
  },
  marital_status: {
    en: "Marital Status",
    ge: "",
  },
  education: {
    en: "Education",
    ge: "",
  },
  languages: {
    en: "Languages",
    ge: "",
  },
  position: {
    en: "Position",
    ge: "",
  },
  company_name: {
    en: "Company Name",
    ge: "",
  },
  start_date: {
    en: "Start Date",
    ge: "",
  },
  end_date: {
    en: "End Date",
    ge: "",
  },
  job_experience: {
    en: "Job Experience",
    ge: "",
  },
  email: {
    en: "Email",
    ge: "",
  },
  gender: {
    en: "Gender",
    ge: "",
  },
  phone: {
    en: "Phone",
    ge: "",
  },
  city: {
    en: "City",
    ge: "",
  },
  job_create_success: {
    en: "Job created successfully.",
    ge: "",
  },
  job_update_success: {
    en: "Job updated successfully.",
    ge: "",
  },
  job_title_en_placeholder: {
    en: "Please enter job title in EN.",
    ge: "",
  },
  job_title_ge_placeholder: {
    en: "Please enter job title in GE.",
    ge: "",
  },
  job_desc_en_placeholder: {
    en: "Please enter job description in EN.",
    ge: "",
  },
  job_desc_ge_placeholder: {
    en: "Please enter job description in GE.",
    ge: "",
  },
  job_image_placeholer: {
    en: "Please add an image.",
    ge: "",
  },
  start_date_error: {
    en: "Please add start date.",
    ge: "",
  },
  end_date_error: {
    en: "Please add end date.",
    ge: "",
  },
}
