import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { RootState } from 'store'
import { drawerWidth } from 'store/constants'
import { handleClose, handleOpen } from 'store/drawerReducer'
import Breadcrumbs from 'ui-components/extended/Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'

const Main = styled('main', {
    shouldForwardProp: (prop: any) => prop !== 'open',
})(({ theme, open }: any) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -197,
            width: '96%',
        },
        [theme.breakpoints.down('md')]: {
            // marginLeft: "20px",
            width: '97%',
            // padding: "16px",
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: "10px",
            // width: "92%",
            // padding: "16px",
            // marginRight: "10px",
        },
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            // marginLeft: "20px",
            width: '97%',
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: "10px",
            width: '97%',
        },
    }),
    marginTop: 48,
}))

export default function MainLayout() {
    const theme = useTheme() as any
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
    const matchUpMd = useMediaQuery(theme.breakpoints.down('sm'))
    const dispatch = useDispatch()
    const leftDrawerOpened = useSelector(
        (state: RootState) => state.drawer.opened
    )

    const handleLeftDrawerToggle = () => {
        dispatch(leftDrawerOpened ? handleClose() : handleOpen())
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* HEADER */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.dominos.dominos_red,
                    transition: leftDrawerOpened
                        ? theme.transitions.create('width')
                        : 'none',
                }}
            >
                <Toolbar style={{ padding: 0 }}>
                    <Header
                        handleLeftDrawerToggle={handleLeftDrawerToggle}
                        drawerOpen={
                            !matchDownMd ? leftDrawerOpened : !leftDrawerOpened
                        }
                    />
                </Toolbar>
            </AppBar>

            {/* DRAWER */}
            <Sidebar
                drawerOpen={!matchUpMd ? leftDrawerOpened : !leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
            />

            {/* MAIN CONTENT */}
            <Main theme={theme} open={leftDrawerOpened}>
                <Breadcrumbs />
                <Outlet />
            </Main>
        </Box>
    )
}
