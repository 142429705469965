import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiServices from 'services/RequestHandler'

export interface ItemsState {
    all_statuses_data: any
    activity: boolean
}

//eslint-disable-next-line
interface APIParams {
    end_point: string
    body: any
}

const initialState: ItemsState = {
    all_statuses_data: [],
    activity: false,
}

export const all_dashboard_statuses = createAsyncThunk(
    '/dashboard',
    async (data: any) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                data.permission_name
            )
            return {
                ...response,
            }
        } catch (err) {
            console.log(err)
        }
    }
)

export const dashboard_slicer = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        handle_clear_form: (state: ItemsState, action: any) => {
            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(
                all_dashboard_statuses.pending,
                (state: any, { payload }: any) => {
                    state.activity = true
                }
            )
            .addCase(
                all_dashboard_statuses.fulfilled,
                (state: any, { payload }: any) => {
                    state.all_statuses_data = payload?.data
                    state.activity = false
                }
            )
    },
})

export const { handle_clear_form } = dashboard_slicer.actions

export default dashboard_slicer.reducer
