import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment"
import apiServices from "services/RequestHandler"
import { dateFormat, timeFormatWithSec, utc_time_zone } from "./constants"

export interface RoleState {
  all_roles_data: any
  all_roles: any
  all_actived_roles: any
  single_role: any
  create_role: any
  update_role: any
  delete_role: any
  activity: boolean
}

interface APIParams {
  end_point: string
  body: any
}

const initialState: RoleState = {
  all_roles_data: [],
  all_roles: [],
  all_actived_roles: [],
  single_role: {},
  create_role: {},
  update_role: {},
  delete_role: {},
  activity: true,
}

export const all_roles = createAsyncThunk("/roles", async (data: APIParams) => {
  try {
    let response = await apiServices.getFromApi(data.end_point, "roles")
    return response
  } catch (err) {
    console.log(err)
  }
})

export const single_roles = createAsyncThunk(
  "/roles/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "roles")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_role = createAsyncThunk(
  "/roles/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "roles"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_role = createAsyncThunk(
  "/roles/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "roles"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const delete_role = createAsyncThunk(
  "/roles/delete",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "roles"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const roles_slicer = createSlice({
  name: "roles",
  initialState,
  reducers: {
    handle_clear_form: (state: RoleState, action: any) => {
      state.create_role = {}
      state.single_role = {}
      state.update_role = {}
      state.delete_role = {}
      state.activity = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(all_roles.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(all_roles.fulfilled, (state, { payload }) => {
        try {
          let roles = [] as any
          let active_role = [] as any

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index]
            roles.push([
              { name: item?._id || "--", hidden: true },
              item?.role_name || "--",
              item?.description || "--",
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#d2112b",
              },
              {
                name: moment(item?.created_at).format(
                  `${dateFormat} ${timeFormatWithSec}`
                ),
              },
              {
                name: item?.added_by,
              },
              {
                name: moment(item?.updated_at).format(
                  `${dateFormat} ${timeFormatWithSec}`
                ),
              },
              {
                name: item?.modified_by || "--",
              },
            ])

            if (item?.is_active) {
              active_role.push([
                { name: item?._id || "--", hidden: true },
                item?.role_name || "--",
                item?.description || "--",
                item?.is_active ? "Active" : "Inactive",
                item?.created_at
                  ? moment(item?.created_at)
                      .utcOffset(utc_time_zone)
                      .format(`${dateFormat} ${timeFormatWithSec}`)
                  : "--",
                item?.updated_at
                  ? moment(item?.created_at)
                      .utcOffset(utc_time_zone)
                      .format(`${dateFormat} ${timeFormatWithSec}`)
                  : "--",
              ])
            }
          }

          state.all_actived_roles = active_role
          state.all_roles_data = payload?.data
          state.all_roles = roles
          state.activity = false
        } catch (error) {}
      })

      .addCase(single_roles.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(single_roles.fulfilled, (state, { payload }) => {
        try {
          state.single_role = {
            ...payload.data,
            refresh_time: Date.now(),
          }
          state.activity = false
        } catch (error) {}
      })

      .addCase(create_role.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(create_role.fulfilled, (state, { payload }) => {
        try {
          state.create_role = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      .addCase(update_role.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(update_role.fulfilled, (state, { payload }) => {
        try {
          state.update_role = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      .addCase(delete_role.pending, (state, { payload }) => {
        state.activity = true
      })

      .addCase(delete_role.fulfilled, (state, { payload }) => {
        try {
          state.delete_role = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })
  },
})

export const { handle_clear_form } = roles_slicer.actions

export default roles_slicer.reducer
