import axios from "axios"
import { clearDataFromLocalStorage } from "utils/localStore.utils"
import CryptoJS from "crypto-js"

export const BASE_URL = process.env.REACT_APP_API_URL
// export const BASE_URL = "https://server.dominospizza.ge/api/v1/"
// export const BASE_URL = "http://192.168.18.8:2023/api/v1"

const secretKey = process.env.REACT_APP_SECRET_KEY || ""

export const encrypt = (text: string): string => {
  return CryptoJS.AES.encrypt(text, secretKey).toString()
}

export const decrypt = (ciphertext: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const getApiRequestHeader = async () => {
  return {
    Accept: "text/plain",
    "Content-Type": "text/plain",
  }
}
let instance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  withCredentials: true,
}) as any

export const updateHeaders = async () => {
  const header = await getApiRequestHeader()
  instance.defaults.headers = header as any
}

export const request = async ({ method, url, data, headers }: any) => {
  if (headers === undefined) {
    await updateHeaders()
  } else {
    if (headers["Content-Type"] === "multipart/form-data") {
      instance.defaults.headers = {
        ...instance.defaults.headers,
        "Content-Type": headers["Content-Type"],
      }
    }
  }
  let dataa = data
  if (headers?.["Content-Type"] !== "multipart/form-data") {
    dataa = encrypt(JSON.stringify(data))
  }

  const promise = instance[method](url, dataa)

  let response

  try {
    response = await promise
  } catch (error: any) {
    let d_data = decrypt(error?.response?.data)
    let _data = JSON.parse(d_data)
    if (typeof _data === "string") {
      _data = JSON.parse(_data)
    }

    if (_data?.response?.status === 401) {
      clearDataFromLocalStorage("token")
      clearDataFromLocalStorage("user")
    } else {
      return _data
    }
  }

  let d_data = decrypt(response?.data)
  let _data = JSON.parse(JSON.parse(d_data))
  return _data
}

export const get = (url: string, permission_name: string, config: any) => {
  return request({
    method: "get",
    url,
    data: {},
    ...config,
  })
}

export const post = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  return request({
    method: "post",
    url,
    data,
    ...config,
  })
}

export const patch = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  return request({
    method: "patch",
    url,
    data,
    ...config,
  })
}
