import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import apiServices from 'services/RequestHandler'
import { dateFormat, timeFormatWithSec, utc_time_zone } from './constants'
import _ from 'lodash'

export interface UserState {
    all_users_data: any
    all_users: any
    single_user: any
    create_user: any
    update_user: any
    user_img: any
    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: UserState = {
    all_users_data: [],
    all_users: [],
    single_user: {},
    create_user: {},
    update_user: {},
    user_img: '',
    activity: false,
}

export const all_users = createAsyncThunk(
    '/users/',
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(data.end_point, 'users')
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const single_users = createAsyncThunk(
    '/users/:id',
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(data.end_point, 'users')
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const create_user = createAsyncThunk(
    '/users/create',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'users'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const update_user = createAsyncThunk(
    '/users/update',
    async (data: APIParams) => {
        try {
            let response = await apiServices.patchFromApi(
                data.end_point,
                data.body,
                'users'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const assign_role_to_user = createAsyncThunk(
    '/users/assign-user',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'users'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const upload_user_image = createAsyncThunk(
    'upload/image',
    async (data: any) => {
        try {
            let formData = new FormData()
            formData.append('file', data.body)
            let response = await apiServices.postFromImage(
                data.end_point,
                formData,
                'storeimage',
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )

            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const users_slicer = createSlice({
    name: 'users',
    initialState,
    reducers: {
        handle_clear_form: (state: UserState, action: any) => {
            state.single_user = {}
            state.create_user = {}
            state.update_user = {}
            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(all_users.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(all_users.fulfilled, (state, { payload }) => {
                try {
                    let users = [] as any

                    for (let index = 0; index < payload?.data.length; index++) {
                        const item = payload?.data[index]
                        users.push([
                            { name: item?._id || '--', hidden: true },
                            item?.lastname
                                ? (_.capitalize(item?.firstname) || '--') +
                                  ' ' +
                                  _.capitalize(item?.lastname)
                                : _.capitalize(item?.firstname) || '--',
                            item?.email || '--',
                            {
                                value: item?.is_active,
                                name: item?.is_active ? 'Active' : 'Inactive',
                                is_bool: true,
                                color: item?.is_active ? '#6EC531' : '#D2112B',
                            },
                            {
                                name: moment(item?.created_at)
                                    .utcOffset(utc_time_zone)
                                    .format(
                                        `${dateFormat} ${timeFormatWithSec}`
                                    ),
                            },
                            {
                                name: item?.added_by || '--',
                            },
                            {
                                name:
                                    item?.updated_at !== item?.created_at
                                        ? moment(item?.updated_at)
                                              .utcOffset(utc_time_zone)
                                              .format(
                                                  `${dateFormat} ${timeFormatWithSec}`
                                              )
                                        : '--',
                            },
                            {
                                name: item?.modified_by || '--',
                            },
                        ])
                    }

                    state.all_users_data = payload?.data
                    state.all_users = users
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })

            .addCase(single_users.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(single_users.fulfilled, (state, { payload }) => {
                try {
                    state.single_user = payload.data

                    state.activity = false
                } catch (error) {
                    state.activity = false
                    console.log(error)
                }
            })

            .addCase(create_user.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(create_user.fulfilled, (state, { payload }) => {
                try {
                    state.create_user = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(update_user.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(update_user.fulfilled, (state, { payload }) => {
                try {
                    state.update_user = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(assign_role_to_user.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(assign_role_to_user.fulfilled, (state, { payload }) => {
                try {
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(upload_user_image.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(upload_user_image.fulfilled, (state, { payload }) => {
                try {
                    state.user_img = payload?.data
                    state.activity = false
                } catch (error) {}
            })
    },
})

export const { handle_clear_form } = users_slicer.actions

export default users_slicer.reducer
