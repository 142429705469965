import { Link } from 'react-router-dom'
import { ButtonBase } from '@mui/material'
import config from 'config'
import DominosLogo from "../../../assets/Logo/DominosLogo.png"

const LogoSection = () => {
    return (
        <ButtonBase
            disableRipple
            disabled
            component={Link}
            to={config.defaultPath}
        >
            <img src={DominosLogo} width={170} alt={'Dominos Logo'} loading="lazy" />
        </ButtonBase>
    )
}

export default LogoSection
